import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../Auth/src/pages/Auth";
import Cookies from 'universal-cookie';

export default function Billing() {
  const cookie = new Cookies(null, {path: '/'});


  useEffect(() => {
    document.body.classList.add('dashboard-body');
  },[]);

  return (
    <iframe src="https://buy.stripe.com/test_8wMg054Ee4UJ8p2144" frameBorder="0"></iframe>
  );
}
