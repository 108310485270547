import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Results.scss";
import { useAuth } from "../../../Auth/src/pages/Auth";
import Cookies from "universal-cookie";
import { BASE_URL } from "../../../config";
import { DeleteDialog } from "../../../Common/components/DeleteDialog";

type Result = {
  test_passed_id: string;
  test_exec_id: string;
  test_name: string;
  name: string;
  firstname: string;
  email: string;
  status: string;
  score: string;
  end_date: string;
};

export default function Results() {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [results, setResults] = useState<Result[]>([]);
  const [showedResults, setShowedResults] = useState<Result[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const cookie = new Cookies(null, { path: "/" });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [currentTestExecId, setCurrentTestExecId] = useState<string | null>(null);
  const [deleteDialog, setDeleteDialog] = useState("Voulez-vous vraiment supprimer ce résultat ?");
  
  useEffect(() => {
    document.body.classList.add("dashboard-body");
    fetchResult();
  }, []);

  useEffect(() => {
    filterTests();
  }, [searchTerm]);

  const handleDeleteTestModal = (testExecId: string) => {
    setCurrentTestExecId(testExecId);
    setIsDeleteDialogOpen(true);
  };
  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  }

  const fetchResult = async () => {
    const headers = new Headers();
    // Add your headers here, for example:
    headers.append("Authorization", `Bearer ${cookie.get("access_token")}`);
    headers.append("Content-Type", "application/json");
    await fetch(`${BASE_URL}/api/v1/test/results-candidates`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (response.status === 401 || response.status === 422) {
          // Navigate to another page if the response status is 401 Unauthorized
          navigate("/unauthorized");
          return "";
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data) {
          const results_data = data as Result[];
          setResults(results_data);
          setShowedResults(results_data);
        }
      })
      .catch((error) => console.error(error));
  };

  const filterTests = () => {
    const filteredResultsBySearch = results.filter(
      (result) =>
        result.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        result.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        result.test_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setShowedResults(filteredResultsBySearch); // Update showedExercises with filtered data
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteResult = async () => {
    const headers = new Headers();
    // Add your headers here, for example:
    headers.append("Authorization", `Bearer ${cookie.get("access_token")}`);
    headers.append("Content-Type", "application/json");
    await fetch(`${BASE_URL}/api/v1/test/result/${currentTestExecId}`, {
      method: "DELETE",
      headers: headers,
    })
      .then((response) => {
        if (response.status === 401 || response.status === 422) {
          // Navigate to another page if the response status is 401 Unauthorized
          navigate("/signin");
          return "";
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data) {
          const updatedshowedResults = showedResults.filter(
            (result) => result.test_exec_id !== currentTestExecId
          );
          const updatedResults = results.filter(
            (result) => result.test_exec_id !== currentTestExecId
          );
          setShowedResults(updatedResults);
          setResults(updatedResults);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col xl:px-24 md:px-20 p-14 justify-center items-center gap-10 min-w-[1000px]">
        <div className="w-1/3 min-w-64">
            <input
              className="input-results w-full border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-600 px-4"
              maxLength={256}
              name="name"
              placeholder="Nom du candidat"
              type="text"
              id="name"
              value={searchTerm}
              onChange={handleInputChange}
            />
        </div>
        <div className="w-full div-results">
          <div className="results-field">
            <div className="grid-result grid-cols-11 xl:grid-cols-12 xl:gap-4 xl:py-2">
              <div className="col-span-2 flex items-center justify-center">
                <div >Nom/Prénom</div>
              </div>
              <div className="col-span-2 xl:col-span-3 flex items-center justify-center">
                <div>Email</div>
              </div>
              <div className="col-span-1 flex items-center justify-center">
                <div >Etat</div>
              </div>
              <div className="col-span-1 flex items-center justify-center">
                <div >Date</div>
              </div>
              <div className="col-span-2 flex items-center justify-center">
                <div>Test</div>
              </div>
              <div className="col-span-1 flex items-center justify-center">
                <div>Score</div>
              </div>
              <div className="col-span-2 flex items-center justify-center">
                <div>Actions</div>
              </div>
            </div>
          </div>
          <div className="div-list mt-2">
            {results.length > 0 ? (
              showedResults.map((result) => (
                <div
                  key={result.test_exec_id}
                  className="grid-result2 grid-cols-11 xl:grid-cols-12 xl:gap-4 xl:py-2 xl:px-4"
                >
                  <div className="col-span-2 flex items-center justify-center">
                    <div className="text-result">
                      {result.name} {result.firstname}
                    </div>
                  </div>
                  <div className="col-span-2 xl:col-span-3 flex items-center justify-center">
                    <div className="text-result">{result.email}</div>
                  </div>
                  <div className="col-span-1 flex items-center justify-center">
                    <div className="text-result">{result.status}</div>
                  </div>
                  <div className="col-span-1 flex items-center justify-center">
                    <div className="text-result">
                      {result.end_date ? result.end_date : "-"}
                    </div>
                  </div>
                  <div className="col-span-2 flex items-center justify-center">
                    <div className="text-result">{result.test_name}</div>
                  </div>
                  <div className="col-span-1 flex items-center justify-center">
                    <div className="text-result">
                      {result.score === "-" ? "-" : `${result.score}/100`}
                    </div>
                  </div>
                  {result.status === "Terminé" && (
                    <div className="col-span-2 xl:gap-2 flex items-center justify-center">
                      <button
                        className=" button-delete bg-red-500 text-white xl:py-1 xl:px-3 rounded-md"
                        onClick={() => handleDeleteTestModal(result.test_exec_id)}
                      >
                        Supprimer
                      </button>
                      <DeleteDialog 
                        isOpen={isDeleteDialogOpen} 
                        onDelete={() => handleDeleteResult()} 
                        onClose={handleCloseDeleteDialog}
                        message={deleteDialog} 
                      />
                      <button
                        className="button-see bg-blue-500 text-white py-1 px-3 rounded-md"
                        onClick={() =>
                          navigate(
                            `/score/${result.test_exec_id}/${result.test_passed_id}`
                          )
                        }
                      >
                        Voir
                      </button>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div>Aucun test envoyé</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
