import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { BASE_URL } from "../../../config";

export default function ReturnPayment (){
    const cookie = new Cookies(null, { path: "/" });
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
  
    useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get('session_id');
  
      fetch(`${BASE_URL}/api/v1/session-status?session_id=${sessionId}`,{
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
        });
    }, []);
  
    if (status === 'open') {
      return (
        <Navigate to="/dashboard/payment" />
      )
    }
  
    if (status === 'complete') {
      return (
        <section id="success" className="flex justify-center items-center min-h-screen">
          <div className="text-center max-w-2xl mx-auto mt-[10vh]">
            <p className="text-lg">
              We appreciate your business! A confirmation email will be sent to {customerEmail}.
            </p>
            <p className="mt-4 text-lg">
              If you have any questions, please email <a href="mailto:orders@example.com" className="text-blue-500 hover:underline">orders@example.com</a>.
            </p>
          </div>
        </section>
      )
    }
  
    return null;
  }