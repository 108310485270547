import React, { useState } from 'react';
import Freetry from './Freetry';
import Explanation from './ExplanationTest';
import PageTransition from './PageTransition'; // Supposons que vous ayez créé un composant de transition.

function Principale() {
  const [showFreetry, setShowFreetry] = useState(true);
  const [showExplanation, setShowExplanation] = useState(false);
  const [animateTransition, setAnimateTransition] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState('');

  const handleLogoClick = (logoName: string) => {
    setSelectedLogo(logoName);
    setAnimateTransition(true);

    setTimeout(() => {
      setShowFreetry(!showFreetry);
      setShowExplanation(!showExplanation);
      setAnimateTransition(false);
    }, 500);
  };

  return (
    <div>
      <PageTransition inProp={animateTransition}>
        {showFreetry && <Freetry onLogoClick={handleLogoClick} selectedLogo={selectedLogo} />}
        {showExplanation && <Explanation onLogoClick={handleLogoClick} selectedLogo={selectedLogo} />}
      </PageTransition>
    </div>
  );
}

export default Principale;