import React from "react";
import "./AccountVerification.scss"

export default function AccountVerification() {
  return (
    <div className="flex flex-col items-center justify-start min-h-screen pt-[5%] font-montserrat text-lg">
      <div className="text-center p-8 w-[80%] min-w-[300px] max-w-[600px] mx-auto">
        <h3 className="text-3xl font-bold text-gray-100 mb-8">Activation du compte</h3>
        <p className="text-xl text-gray-300 mb-6">
          Merci pour votre inscription ! Pour activer votre compte, veuillez cliquer sur le lien d'activation que nous vous avons envoyé par email.
        </p>
      </div>
      <button 
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mb-8"
        onClick={() => window.location.href = '/'}
      >
        Accueil
      </button>
    </div>
  );
}
