import React, { MouseEventHandler } from "react";

interface ButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  children: React.ReactNode;
  classes?: Array<string>;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, classes }) => {
  const finalClasses = classes ? `btn ${classes.join(' ')}` : 'btn';
  return (
    <button onClick={onClick} className={finalClasses}>
      {children}
    </button>
  );
};

export default Button;
