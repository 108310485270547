import { useEffect } from "react";
import { useNavigate} from "react-router-dom";
import Button from "../../../Common/components/Button";
import "./Unauthorized.scss";

export default function ChapterCompleted() {
  const navigate = useNavigate();

  const handleButtonLogin = () => {
    navigate("/signin");
  };
  const handleButtonPricing = () => {
    navigate("/#price");
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen pt-[10%] font-montserrat text-lg">
      <div className="text-center p-8">
        <h3 className="text-3xl font-bold text-gray-100 mb-4">Contenu exclusif</h3>
        <p className="text-xl text-gray-300 mb-6">Pour accéder à ce contenu réservé, veuillez vous connecter ou souscrire à un abonnement.</p>
      </div>
      <div className="mt-8 space-x-4">
        <button onClick={handleButtonPricing} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded text-xl">
          Découvrir nos offres
        </button>
        <button onClick={handleButtonLogin} className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded text-xl">
          Connexion
        </button>
      </div>
    </div>
  );
}