import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./Freetry.scss";

import docker from "../../../Common/assets/Docker_Logo.png";
import helm from "../../../Common/assets/Helm_Logo.png";
import ansible from "../../../Common/assets/Ansible_Logo.png";
import terraform from "../../../Common/assets/Terraform_Logo.svg";

interface FreetryProps {
  selectedLogo: string; // Définir le type de selectedLogo comme une chaîne ou null
  onLogoClick: (logoName: string ) => void; // Définir le type de onLogoClick
}

export default function Freetry({ onLogoClick, selectedLogo }: FreetryProps) {
  const navigate = useNavigate();
  
  
  // État pour gérer la mise en surbrillance
  const [isHighlighted, setHighlighted] = useState(false);

  // Fonction pour activer/désactiver la mise en surbrillance
  const toggleHighlight = () => {
    setHighlighted(!isHighlighted);
  };

  const handleLogoClick = (destination: string) => {
    // Utilisez navigate pour naviguer vers la page souhaitée
    navigate(destination);
  };

  return (
    <div className="page-container">
      <h3 className="title">Choisissez une technologie</h3>
      <div className={`rectangle ${isHighlighted ? 'highlighted' : ''}`} onMouseEnter={toggleHighlight} onMouseLeave={toggleHighlight}>
        <div className="rowfree">
          <div className="box" onClick={() => onLogoClick("Docker")}>
            <img src={docker} alt="Logo Docker" className="logo" />
            <p>Docker</p>
          </div>
          <div className="box" onClick={() => onLogoClick("Terraform")}>
            <img src={terraform} alt="Logo Terraform" className="logo" />
            <p>Terraform</p>
          </div>
        </div>
        <div className="rowfree">
          <div className="box" onClick={() => onLogoClick("Ansible")}>
            <img src={ansible} alt="Logo Ansible" className="logo" />
            <p>Ansible</p>
          </div>
          <div className="box" onClick={() => onLogoClick("Helm")}>
            <img src={helm} alt="Logo Helm" className="logo" />
            <p>Helm</p>
          </div>
        </div>
      </div>
    </div>
  );
}