import React, { ReactNode } from 'react';
import { Transition } from 'react-transition-group';

interface PageTransitionProps {
  children: ReactNode;
  inProp: boolean;
}

const PageTransition = ({ children, inProp }: PageTransitionProps) => (
  <Transition in={inProp} timeout={500}>
    {(state) => {
       const transformX = state === 'entered' ? '0' : state === 'exited' ? '0' : '-100%';
      return (
        <div
          style={{
            transition: 'all 0.4s',
            transform: `translateX(${transformX})`,
          }}
        >
          {children}
        </div>
      );
    }}
  </Transition>
);

export default PageTransition;