import React, { useState } from "react";

export interface FaqItemType {
  question: string;
  answer: string;
}

const faqData = [
    {
        question: "Comment fonctionne les crédits ?",
        answer:
          "Lors de l'envoi d'un test à votre candidat, un ou plusieurs crédits seront débités de votre compte en fonction de la durée et des ressources nécessaires pour le test. En moyenne, un crédit correspond à un test envoyé.",
      },
  {
    question: "Proposez-vous des abonnements ?",
    answer:
      "Nous ne proposons pas d'abonnement à nos clients.",
  },
  {
    question: "J'ai besoin d'une facture",
    answer:
      "Nous utilisons Stripe comme partenaire de paiement, ce qui vous permet de générer une facture lors de l'achat de crédits.",
  },
  {
    question: "Comment puis-je tester gratuitement Calinfra ?",
    answer:
      "Après votre première création de compte vous disposez de 5 crédits gratuits vous permettant de tester notre solution en envoyant des tests à vos candidats.",
  },
  {
    question: "Et si je ne trouve pas de tests appropriés à mon entretien ?",
    answer:
      "Vous pouvez nous contacter à l'adresse \'calinfra.enterprise@gmail.com\' pour que nous puissions élaborer sur-mesure des exercices faits pour vous.",
  },
  // Add more questions and answers here
];

const FaqItem: React.FC<FaqItemType> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item mb-4">
      <div
        className={`faq-question flex justify-between items-center cursor-pointer p-4 border-neutral-600 ${isOpen ? 'rounded-t-lg border-t-2 border-x-2' : 'rounded-lg border-2 '}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium text-neutral-50">{question}</span>
        <svg
          className={`faq-icon w-6 h-6 text-green-500 transition-transform duration-200 ${
            isOpen ? "transform rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </div>
      {isOpen && (
        <div className="faq-answer p-4 rounded-b-lg text-neutral-300 border-neutral-600 border-x-2 border-b-2">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

const Faq = () => {
  return (
    <div className="max-w-2xl mx-auto p-6 rounded-lg shadow-lg py-40">
      <h2 className="text-4xl font-bold mb-20 text-center">Foire Aux Questions</h2>
      <div id="faq">
        {faqData.map((item, index) => (
          <FaqItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div>
    </div>
  );
};

export default Faq;
