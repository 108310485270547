import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Button from "../../../Common/components/Button";
import Confetti from "react-dom-confetti";
import "./ChapterCompleted.scss";

export default function ChapterCompleted() {
  const { chapterId } = useParams<{ chapterId: string }>();
  const [showConfetti, setShowConfetti] = useState(false);
  const navigate = useNavigate();
  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 60,
    elementCount: 70,
    dragFriction: 0.1,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#4d4dff", "#e60000", "#ffa31a", "#00b33c"]
  };
  useEffect(() => {
    setShowConfetti(true);
  }, []);
  const handleButtonClick = () => {
    navigate("/exercise/0/8");
  };

  return (
    <div className="chapter-complete">
      <Confetti active={showConfetti} config={config} />
      <div className="message">
        <h3>Bravo !</h3>
        <p>Vous avez terminé le chapitre {chapterId} du cours sur Vim.</p>
      </div>
      <div className="button-container">
        <Button onClick={handleButtonClick}>Commencer le Chapitre {Number(chapterId) + 1}</Button>
      </div>
    </div>
  );
}