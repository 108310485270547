import { Link } from "react-router-dom";
import Button from "../../../Common/components/Button";
import "./Pricing.scss";

export default function Pricing() {
  return (
    <div className="pricing">
      <header>
        <h1>Offres</h1>
        <p>Choisissez l'offre qui vous convient</p>
      </header>
      <section className="pricing-plans">
        <div className="pricing-plan">
          <h2>Abonnement</h2>
          <p>Pour l'ambitieux et le curieux</p>
          <h3>15€/mois</h3>
          <ul>
            <li>Accès illimité à la plateforme</li>
            <li>Tous les cours disponibles</li>
            <li>Accès Forum de support</li>
          </ul>
          <Button>Choisir</Button>
        </div>
        <div className="pricing-plan">
          <h2>Location</h2>
          <p>For professional developers</p>
          <h3>20€</h3>
          <ul>
            <li>Accès à la plateforme illimité</li>
            <li>Seulement le cours acheté est disponible</li>
            <li>Accès Forum de support</li>
          </ul>
          <Button>Choisir</Button>
        </div>
      </section>
      <section className="pricing-cta">
        <h2>Rejoignez des millions de développeurs et d'entreprises qui construisent de meilleurs logiciels</h2>
      </section>
    </div>
  );
}
