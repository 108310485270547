import { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import "./Freetry.scss";

interface ExplanationProps {
  selectedLogo: string; // Définir le type de selectedLogo comme une chaîne ou null
  onLogoClick: (logoName: string) => void; // Définir le type de onLogoClick
}

export default function ExplanationTest({ selectedLogo, onLogoClick }: ExplanationProps) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const explanationText = selectedLogo;

  function handleClick() {
    //navigate('/exercise/0/0');
    navigate('/exerciseTest/Docker');
  }

  return (
    <div className="white-rectangle-page">
    <div className="white-rectangle">
      <p>Vous disposez de 10min</p>
      <p>Vous êtes évalué sur {explanationText}</p>
    </div>
    <div className="button-container">
      <button className="btnOk" onClick={handleClick}>
        Ok
      </button>
    </div>
  </div>
  );
}