import React from "react";
import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";

export default function ExerciseHeader() {
  return (
    <header className='ex-header btn-group'>
      <Button>
        <FontAwesomeIcon
          icon={solid("circle-question")}
          style={{ marginRight: "10px" }}
        />
        Besoins d'aide
      </Button>
      <Button classes={["btn-white"]}>
        Français
        <FontAwesomeIcon
          icon={solid("caret-down")}
          style={{ marginLeft: "10px" }}
        />
      </Button>
    </header>
  );
}
