import React, { useState, useEffect, useCallback } from "react";
import "./Payment.scss";
import Cookies from "universal-cookie";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { BASE_URL } from "../../../config";
import { STRIPE_SECRET_KEY } from "../../../config";

const stripePromise = loadStripe(STRIPE_SECRET_KEY);

const CheckoutForm = () => {
  const cookie = new Cookies(null, { path: "/" });

  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return fetch(`${BASE_URL}/api/v1/create-checkout-session`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${cookie.get("access_token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = {fetchClientSecret};

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div id="checkout" className="w-full">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
      </div>
    </div>
  )
}


export default function Payment() {
  const cookie = new Cookies(null, { path: "/" });

  useEffect(() => {
    document.body.classList.add("dashboard-body");
    
  }, []);

  return (
    <CheckoutForm />
  );
}
