import { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Flex, Input } from "antd";
import type { SearchProps } from 'antd/es/input/Search';
import "./ModifyTest.scss";


const boxStyle: React.CSSProperties = {
    marginLeft: '50px',
    marginRight: '50px',
    width: '100%',
    height: 120,
    borderRadius: 6,
    border: '1px solid #40a9ff',
  };

const { Search } = Input;


export default function ModifyTest() {
    const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);


    useEffect(() => {
        document.body.classList.add('dashboard-body');
      }, []);

    return (
        <Flex gap="middle" align="start">
            <Flex style={boxStyle} justify="center" align="center" >
                <Search
                    placeholder="input search text"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={onSearch}
                />
            </Flex>
            <div className="separationbar"></div>
            <Flex style={boxStyle} justify="center" align="center">
                <p>coucou</p>
            </Flex>
        </Flex>
    );
}