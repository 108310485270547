import { useEffect, useState } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Spin, Flex, ConfigProvider } from "antd";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import { Disclosure } from "@headlessui/react";
import "./Score.scss";
import Cookies from "universal-cookie";
import { BASE_URL } from "../../../config";

type Technology = {
  [key: number]: string;
};

const boxStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "80%",
  padding: "50px",
};

type Exercise = {
  name: string;
  difficulty: number;
  technologies: Technology;
  content: string;
  explication: string;
  score: number;
};

interface DisclosureProps {
  exercise: Exercise;
}

const DisclosureExercise: React.FC<DisclosureProps> = ({ exercise }) => {
  const content2 = `
### Tâche 1 : Supprimer une image docker

Une image nommée \`alpine-test\` est présente sur la machine.

Supprimez cette image.

> **Pour copier/coller dans le terminal:**
> 
> **Windows**
> - Copy - CTRL + C
> - Paste - SHIFT + INSERT
>
> **Mac**
> - Copy - COMMAND + C
> - Paste - COMMAND + SHIFT + V
>
> **Linux**
> - Copy - CTRL + C
> - Paste - CTRL + V
`;
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Panel>
            <div className="div-block-52">
              <div className="text-block-78">Technologie :</div>
              <div className="text-block-79">
                {Object.values(exercise.technologies).join(", ")}
              </div>
            </div>
            <div className="div-block-53">
              <div className="text-block-80">Difficulté : </div>
              {[...Array(exercise["difficulty"])].map((_, index) => (
                <img
                  key={index}
                  src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/6601fb19c4d133049ef51fbc_star-solid(1).svg"
                  loading="lazy"
                  width={20}
                  alt=""
                  className="image-19"
                />
              ))}
              {[...Array(5 - exercise["difficulty"])].map((_, index) => (
                <img
                  key={exercise["difficulty"] + index}
                  src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65cf217f91b2c657685f7601_star-solid.svg"
                  loading="lazy"
                  width={20}
                  alt=""
                  className="image-19"
                />
              ))}
            </div>
            <div className="div-block-52">
              <div className="text-block-78">Score :</div>
              <div className="text-block-79">{exercise.score}/100</div>
            </div>
            <div>
              <div className="text-block-81">Description :</div>
            </div>
            <p className="paragraph-6">
              <ReactMarkdown remarkPlugins={[remarkGfm]} className="sujet3">
                {exercise["content"]}
                {/* {content2} */}
              </ReactMarkdown>
            </p>
            {exercise.explication && (
              <>
                <div>
                  <div className="text-block-84">Explications :</div>
                </div>
                <div className="paragraph-7">
                  {exercise.explication.split("\n").map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </div>
              </>
            )}
          </Disclosure.Panel>
          <Disclosure.Button style={{ width: "100%" }}>
            <div className={open ? "div-block-54" : "div-block-54-copy"}>
              <div
                className="text-block-85"
                style={{ display: open ? "none" : "block" }}
              >
                Voir les détails
              </div>
              <FontAwesomeIcon
                icon={solid("circle-chevron-up")}
                style={{ display: "block", fontSize: "32px", color: "#808080" }}
                className={`${open ? "rotate-180 transform" : ""}`}
              />
            </div>
          </Disclosure.Button>
        </>
      )}
    </Disclosure>
  );
};

export default function Score() {
  const { testExecId, testPassedId } = useParams<{
    testExecId: string;
    testPassedId: string;
  }>();
  const [candidateName, setCandidateName] = useState<string>("");
  const [candidateFirstname, setCandidateFirstname] = useState<string>("");
  const [testName, setTestName] = useState<string>("");
  const [execTime, setExecTime] = useState<string>("");
  const [resultTestScore, setResultTestScore] = useState<number>(0);
  const [listExercises, setListExercises] = useState<Exercise[]>([]);
  const [isResultReady, setIsResultReady] = useState(false);
  const cookie = new Cookies(null, { path: "/" });
  const navigate = useNavigate();
  const [retryCount, setRetryCount] = useState(0);

  const fetchResultState = async () => {
    const headers = new Headers();
    // Add your headers here, for example:
    headers.append("Authorization", `Bearer ${cookie.get("access_token")}`);
    headers.append("Content-Type", "application/json");
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/test/${testExecId}/result-state`,
        {
          method: "GET",
          headers: headers,
        }
      );

      if (response.status === 401 || response.status === 422) {
        navigate("/signin");
        return;
      }

      const data = await response.json();
      if (data) {
        setIsResultReady(data.state);
        if (!data.state && retryCount < 100) {
          setRetryCount((prevCount) => prevCount + 1);
        }
      }
    } catch (error) {
      console.error("Error fetching result state:", error);
      if (retryCount < 100) {
        setRetryCount((prevCount) => prevCount + 1);
      }
    }
  };

  const fetchCandidate = async () => {
    const headers = new Headers();
    // Add your headers here, for example:
    headers.append("Authorization", `Bearer ${cookie.get("access_token")}`);
    headers.append("Content-Type", "application/json");
    await fetch(`${BASE_URL}/api/v1/test/${testExecId}/candidate`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (response.status === 401 || response.status === 422) {
          // Navigate to another page if the response status is 401 Unauthorized
          navigate("/signin");
          return "";
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data) {
          setCandidateName(data.name);
          setCandidateFirstname(data.firstname);
        }
      })
      .catch((error) => console.error(error));
  };

  const fetchResult = async () => {
    const headers = new Headers();
    // Add your headers here, for example:
    headers.append("Authorization", `Bearer ${cookie.get("access_token")}`);
    headers.append("Content-Type", "application/json");
    await fetch(`${BASE_URL}/api/v1/test/${testExecId}/result`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (response.status === 401 || response.status === 422) {
          // Navigate to another page if the response status is 401 Unauthorized
          navigate("/signin");
          return "";
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data) {
          setResultTestScore(data.mean_score);
        }
      })
      .catch((error) => console.error(error));
  };

  const fetchTestPassed = async () => {
    const headers = new Headers();
    // Add your headers here, for example:
    headers.append("Authorization", `Bearer ${cookie.get("access_token")}`);
    headers.append("Content-Type", "application/json");
    await fetch(`${BASE_URL}/api/v1/test/${testPassedId}/test-passed`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (response.status === 401 || response.status === 422) {
          // Navigate to another page if the response status is 401 Unauthorized
          navigate("/signin");
          return "";
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data) {
          setExecTime(data.exec_time);
          setTestName(data.test_name);
        }
      })
      .catch((error) => console.error(error));
  };

  const fetchExercises = async () => {
    const headers = new Headers();
    // Add your headers here, for example:
    headers.append("Authorization", `Bearer ${cookie.get("access_token")}`);
    headers.append("Content-Type", "application/json");
    await fetch(`${BASE_URL}/api/v1/test/${testExecId}/score-exercises`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (response.status === 401 || response.status === 422) {
          // Navigate to another page if the response status is 401 Unauthorized
          navigate("/signin");
          return "";
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data) {
          const exercises = (data as Exercise[]).map(exercise => ({
            ...exercise,
            content: exercise.content.replace(/\\n/g, "\n"),
          }));
          
          // Set the processed array to your state
          setListExercises(exercises);
          
          // Log the updated exercises to verify
          console.log(exercises);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    document.body.classList.add("dashboard-body");
    fetchResultState();
  }, []);

  useEffect(() => {
    if (retryCount > 0 && retryCount < 101 && !isResultReady) {
      const timer = setTimeout(() => {
        fetchResultState();
      }, 5000); // Wait 5 seconds before retrying

      return () => clearTimeout(timer);
    }
  }, [retryCount, isResultReady]);

  useEffect(() => {
    fetchCandidate();
    fetchResult();
    fetchTestPassed();
    fetchExercises();
  }, [isResultReady]);

  const handleCloseClick = () => {
    navigate("/dashboard/resultatcandidats");
  };

  return (
    <>
      {!isResultReady ? (
        <Flex
          style={boxStyle}
          justify="center"
          align="center"
          vertical={true}
          gap="large"
        >
          <ConfigProvider
            theme={{
              components: {
                Spin: {
                  dotSizeLG: 64,
                },
              },
            }}
          >
            <Spin size="large" />
          </ConfigProvider>
          <h5 style={{ color: "#1572f5" }}>
            Veuillez patienter, nous calculons votre score. Cela peut prendre
            quelques minutes...
          </h5>
        </Flex>
      ) : (
        <div>
          <div className="div-block-49">
            <div className="text-block-68">Calinfra</div>
            <div className="div-block-50">
              <div className="text-block-69">
                {candidateName} {candidateFirstname}
              </div>
            </div>
          </div>
          <div className="w-layout-blockcontainer container-21 w-container">
            <div className="w-layout-blockcontainer container-22 w-container">
              <div className="text-block-70">Examen :</div>
              <div className="text-block-71">{testName}</div>
            </div>
            <div className="w-layout-blockcontainer container-23 w-container">
              <div className="text-block-72">Score :</div>
              <div className="div-block-51">
                <FontAwesomeIcon
                  icon={solid("caret-down")}
                  style={{
                    position: "absolute",
                    top: "-30px",
                    left: `${resultTestScore}%`,
                    fontSize: "32px",
                    color: "#808080",
                    transform: "translateX(-50%)",
                  }}
                />
                <div
                  className="div-bar-score"
                  style={{
                    position: "absolute",
                    left: `${resultTestScore}%`,
                    transform: "translateX(-50%)",
                  }}
                />
              </div>
              <div className="text-block-73">{resultTestScore} %</div>
            </div>
            <div className="w-layout-blockcontainer container-24 w-container">
              <div className="text-block-74">Temps du candidat : </div>
              <div className="text-block-75">{execTime}</div>
              <img
                src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65d252f1772349200f06dcef_stopwatch-solid(2).svg"
                loading="lazy"
                width={25}
                alt="chrono"
              />
            </div>
            <div className="w-layout-blockcontainer container-25 w-container" />
            <div className="w-layout-blockcontainer w-container">
              <div className="text-block-76">Détails : </div>
            </div>
            <div className="w-layout-blockcontainer container-26 w-container">
              {listExercises.length > 0 ? (
                listExercises.map((exercise) => (
                  <React.Fragment>
                    <div className="w-layout-blockcontainer container-27 w-container">
                      <div
                        className={
                          exercise["score"] < 75
                            ? "text-block-82"
                            : "text-block-77"
                        }
                      >
                        Exercise: {exercise["name"]}
                      </div>
                      <img
                        src={
                          exercise.score < 75
                            ? "https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65d24fa4c71e84a616b97106_xmark-solid(1).svg"
                            : "https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65d22e0bf7d3a9b675c035ad_check-solid(1).svg"
                        }
                        loading="lazy"
                        width={20}
                        alt=""
                      />
                    </div>
                    <div className="div-block-55">
                      <DisclosureExercise exercise={exercise} />
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <div> </div>
              )}
            </div>
          </div>
          <div className="w-layout-blockcontainer container-30 w-container">
            {/* <a href="#" className="button-12 w-button">
              Export PDF
            </a> */}
            <button
              className="button-13 w-button"
              onClick={() => handleCloseClick()}
            >
              Fermer
            </button>
          </div>
          <div style={{ display: "inline" }}>
            <div id="drag-ghost" />
            <div
              className="wf-x2rzm9"
              data-theme="d2Light"
              style={{ display: "contents" }}
            >
              <style
                dangerouslySetInnerHTML={{
                  __html: "* { box-sizing: border-box; }",
                }}
              />
              <div
                className="wf-81ocvw"
                data-wf-size-context="large"
                style={{ display: "contents" }}
              >
                <div
                  style={{
                    color: "rgb(64, 64, 64)",
                    fontFamily:
                      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
                    fontSize: "14px",
                    lineHeight: "16px",
                    textAlign: "left",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      width: "100%",
                      zIndex: 2147483547,
                    }}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      position: "absolute",
                      width: "0px",
                      height: "0px",
                      overflow: "hidden",
                    }}
                  >
                    <defs>
                      <svg
                        id="page-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 13 16"
                        width={15}
                        height={16}
                      >
                        <path
                          mask="none"
                          fill="currentColor"
                          d="M8 1H1v13h11V5z"
                        />
                      </svg>
                      <svg
                        id="page-draft-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 13 16"
                        width={15}
                        height={16}
                      >
                        <mask id="page-draft-icon-draft-badge-mask">
                          <rect
                            fill="white"
                            x={0}
                            y={0}
                            width="100%"
                            height="100%"
                          />
                          <path
                            transform="translate(-5, 6)"
                            fill="#000"
                            d="M12.5459415,5.18198052 L6.90879015,10.8191319 L0.544829121,12.9404523 L2.66614946,6.57649122 L8.30330086,0.939339828 C9.27961159,-0.0369709012 10.862524,-0.0369709012 11.8388348,0.939339828 L12.5459415,1.64644661 C13.5222523,2.62275734 13.5222523,4.20566979 12.5459415,5.18198052 Z"
                          />
                        </mask>
                        <path
                          mask="url(#page-draft-icon-draft-badge-mask)"
                          fill="currentColor"
                          d="M8 1H1v13h11V5z"
                        />
                        <g transform="translate(-5, 6)">
                          <path
                            fill="#F67E28"
                            fillRule="evenodd"
                            d="M7.94974747,4.12132034 L9.36396103,5.53553391 L5.82842712,9.07106781 L3.70710678,9.77817459 L4.41421356,7.65685425 L7.94974747,4.12132034 Z M10.4246212,2.35355339 L11.131728,3.06066017 C11.3269901,3.25592232 11.3269901,3.57250481 11.131728,3.76776695 L10.0710678,4.82842712 L8.65685425,3.41421356 L9.71751442,2.35355339 C9.91277657,2.15829124 10.2293591,2.15829124 10.4246212,2.35355339 Z"
                          />
                        </g>
                      </svg>
                      <svg
                        id="page-protected-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 13 16"
                        width={15}
                        height={16}
                      >
                        <mask id="page-protected-icon-lock-badge-mask">
                          <rect
                            fill="white"
                            x={0}
                            y={0}
                            width="100%"
                            height="100%"
                          />
                          <path
                            transform="translate(-3, 4)"
                            fill="black"
                            d="M10 5v8H0V5a5 5 0 0 1 10 0z"
                          />
                        </mask>
                        <path
                          mask="url(#page-protected-icon-lock-badge-mask)"
                          fill="currentColor"
                          d="M8 1H1v13h11V5z"
                        />
                        <g transform="translate(-3, 4)">
                          <path fill="#E6BE49" d="M2 7h6v4H2z" />
                          <path
                            fill="#D6D6D6"
                            d="M8 6V5a3 3 0 0 0-6 0v1h1.1V5a1.9 1.9 0 0 1 3.8 0v1H8z"
                          />
                        </g>
                      </svg>
                      <svg
                        id="page-ancestor-protected-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 13 16"
                        width={15}
                        height={16}
                      >
                        <mask id="page-ancestor-protected-icon-lock-badge-mask">
                          <rect
                            fill="white"
                            x={0}
                            y={0}
                            width="100%"
                            height="100%"
                          />
                          <path
                            transform="translate(-3, 4)"
                            fill="black"
                            d="M10 5v8H0V5a5 5 0 0 1 10 0z"
                          />
                        </mask>
                        <path
                          mask="url(#page-ancestor-protected-icon-lock-badge-mask)"
                          fill="currentColor"
                          d="M8 1H1v13h11V5z"
                        />
                        <g transform="translate(-3, 4)">
                          <path fill="currentColor" d="M2 7h6v4H2z" />
                          <path
                            fill="currentColor"
                            d="M8 6V5a3 3 0 0 0-6 0v1h1.1V5a1.9 1.9 0 0 1 3.8 0v1H8z"
                          />
                        </g>
                      </svg>
                      <svg
                        id="page-members-only-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 13 16"
                        width={15}
                        height={16}
                      >
                        <mask id="page-members-only-icon-member-badge-mask">
                          <rect
                            fill="white"
                            x={0}
                            y={0}
                            width="100%"
                            height="100%"
                          />
                          <path
                            transform="translate(-3, 4)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 10V13H11V10C11 8.68166 10.3622 7.51228 9.37837 6.78357C9.77337 6.11415 10 5.33354 10 4.5C10 2.01472 7.98528 0 5.5 0C3.01472 0 1 2.01472 1 4.5C1 5.33354 1.22663 6.11415 1.62163 6.78357C0.637782 7.51228 0 8.68166 0 10Z"
                            fill="black"
                          />
                        </mask>
                        <path
                          mask="url(#page-members-only-icon-member-badge-mask)"
                          fill="currentColor"
                          d="M8 1H1v13h11V5z"
                        />
                        <g fill="#5ea6f7" transform="translate(-1, 6)">
                          <path d="m3.5 5c1.38071 0 2.5-1.11929 2.5-2.5s-1.11929-2.5-2.5-2.5-2.5 1.11929-2.5 2.5 1.11929 2.5 2.5 2.5z" />
                          <path d="m2 6c-1.104569 0-2 .89543-2 2v1h7v-1c0-1.10457-.89543-2-2-2z" />
                        </g>
                      </svg>
                      <svg
                        id="page-ancestor-members-only-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 13 16"
                        width={15}
                        height={16}
                      >
                        <mask id="page-ancestor-members-only-icon-member-badge-mask">
                          <rect
                            fill="white"
                            x={0}
                            y={0}
                            width="100%"
                            height="100%"
                          />
                          <path
                            transform="translate(-3, 4)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 10V13H11V10C11 8.68166 10.3622 7.51228 9.37837 6.78357C9.77337 6.11415 10 5.33354 10 4.5C10 2.01472 7.98528 0 5.5 0C3.01472 0 1 2.01472 1 4.5C1 5.33354 1.22663 6.11415 1.62163 6.78357C0.637782 7.51228 0 8.68166 0 10Z"
                            fill="black"
                          />
                        </mask>
                        <path
                          mask="url(#page-ancestor-members-only-icon-member-badge-mask)"
                          fill="currentColor"
                          d="M8 1H1v13h11V5z"
                        />
                        <g fill="currentColor" transform="translate(-1, 6)">
                          <path d="m3.5 5c1.38071 0 2.5-1.11929 2.5-2.5s-1.11929-2.5-2.5-2.5-2.5 1.11929-2.5 2.5 1.11929 2.5 2.5 2.5z" />
                          <path d="m2 6c-1.104569 0-2 .89543-2 2v1h7v-1c0-1.10457-.89543-2-2-2z" />
                        </g>
                      </svg>
                      <svg
                        id="folder-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 15 16"
                        width={17}
                        height={16}
                      >
                        <path
                          mask="none"
                          fill="currentColor"
                          d="M9.498 3c-.132.17-.29.37-.487.6-.48.56-1.114 1.24-1.845 1.752-.493.333-1.034.635-1.747.648H1v7h13V3H9.498zm-3.537.75a6.3 6.3 0 0 0 .948-.81c.184-.187.354-.377.513-.56L6.125 1H1v3h4.416c.016.01.255-.05.545-.25z"
                        />
                      </svg>
                      <svg
                        id="folder-protected-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 15 16"
                        width={17}
                        height={16}
                      >
                        <mask id="folder-protected-icon-lock-badge-mask">
                          <rect
                            fill="white"
                            x={0}
                            y={0}
                            width="100%"
                            height="100%"
                          />
                          <path
                            transform="translate(-3, 4)"
                            fill="black"
                            d="M10 5v8H0V5a5 5 0 0 1 10 0z"
                          />
                        </mask>
                        <path
                          mask="url(#folder-protected-icon-lock-badge-mask)"
                          fill="currentColor"
                          d="M9.498 3c-.132.17-.29.37-.487.6-.48.56-1.114 1.24-1.845 1.752-.493.333-1.034.635-1.747.648H1v7h13V3H9.498zm-3.537.75a6.3 6.3 0 0 0 .948-.81c.184-.187.354-.377.513-.56L6.125 1H1v3h4.416c.016.01.255-.05.545-.25z"
                        />
                        <g transform="translate(-3, 4)">
                          <path fill="#E6BE49" d="M2 7h6v4H2z" />
                          <path
                            fill="#D6D6D6"
                            d="M8 6V5a3 3 0 0 0-6 0v1h1.1V5a1.9 1.9 0 0 1 3.8 0v1H8z"
                          />
                        </g>
                      </svg>
                      <svg
                        id="folder-ancestor-protected-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 15 16"
                        width={17}
                        height={16}
                      >
                        <mask id="folder-ancestor-protected-icon-lock-badge-mask">
                          <rect
                            fill="white"
                            x={0}
                            y={0}
                            width="100%"
                            height="100%"
                          />
                          <path
                            transform="translate(-3, 4)"
                            fill="black"
                            d="M10 5v8H0V5a5 5 0 0 1 10 0z"
                          />
                        </mask>
                        <path
                          mask="url(#folder-ancestor-protected-icon-lock-badge-mask)"
                          fill="currentColor"
                          d="M9.498 3c-.132.17-.29.37-.487.6-.48.56-1.114 1.24-1.845 1.752-.493.333-1.034.635-1.747.648H1v7h13V3H9.498zm-3.537.75a6.3 6.3 0 0 0 .948-.81c.184-.187.354-.377.513-.56L6.125 1H1v3h4.416c.016.01.255-.05.545-.25z"
                        />
                        <g transform="translate(-3, 4)">
                          <path fill="currentColor" d="M2 7h6v4H2z" />
                          <path
                            fill="currentColor"
                            d="M8 6V5a3 3 0 0 0-6 0v1h1.1V5a1.9 1.9 0 0 1 3.8 0v1H8z"
                          />
                        </g>
                      </svg>
                      <svg
                        id="folder-members-only-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 15 16"
                        width={17}
                        height={16}
                      >
                        <mask id="folder-members-only-icon-member-badge-mask">
                          <rect
                            fill="white"
                            x={0}
                            y={0}
                            width="100%"
                            height="100%"
                          />
                          <path
                            transform="translate(-3, 4)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 10V13H11V10C11 8.68166 10.3622 7.51228 9.37837 6.78357C9.77337 6.11415 10 5.33354 10 4.5C10 2.01472 7.98528 0 5.5 0C3.01472 0 1 2.01472 1 4.5C1 5.33354 1.22663 6.11415 1.62163 6.78357C0.637782 7.51228 0 8.68166 0 10Z"
                            fill="black"
                          />
                        </mask>
                        <path
                          mask="url(#folder-members-only-icon-member-badge-mask)"
                          fill="currentColor"
                          d="M9.498 3c-.132.17-.29.37-.487.6-.48.56-1.114 1.24-1.845 1.752-.493.333-1.034.635-1.747.648H1v7h13V3H9.498zm-3.537.75a6.3 6.3 0 0 0 .948-.81c.184-.187.354-.377.513-.56L6.125 1H1v3h4.416c.016.01.255-.05.545-.25z"
                        />
                        <g fill="#5ea6f7" transform="translate(-1, 6)">
                          <path d="m3.5 5c1.38071 0 2.5-1.11929 2.5-2.5s-1.11929-2.5-2.5-2.5-2.5 1.11929-2.5 2.5 1.11929 2.5 2.5 2.5z" />
                          <path d="m2 6c-1.104569 0-2 .89543-2 2v1h7v-1c0-1.10457-.89543-2-2-2z" />
                        </g>
                      </svg>
                      <svg
                        id="folder-ancestor-members-only-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 15 16"
                        width={17}
                        height={16}
                      >
                        <mask id="folder-ancestor-members-only-icon-member-badge-mask">
                          <rect
                            fill="white"
                            x={0}
                            y={0}
                            width="100%"
                            height="100%"
                          />
                          <path
                            transform="translate(-3, 4)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 10V13H11V10C11 8.68166 10.3622 7.51228 9.37837 6.78357C9.77337 6.11415 10 5.33354 10 4.5C10 2.01472 7.98528 0 5.5 0C3.01472 0 1 2.01472 1 4.5C1 5.33354 1.22663 6.11415 1.62163 6.78357C0.637782 7.51228 0 8.68166 0 10Z"
                            fill="black"
                          />
                        </mask>
                        <path
                          mask="url(#folder-ancestor-members-only-icon-member-badge-mask)"
                          fill="currentColor"
                          d="M9.498 3c-.132.17-.29.37-.487.6-.48.56-1.114 1.24-1.845 1.752-.493.333-1.034.635-1.747.648H1v7h13V3H9.498zm-3.537.75a6.3 6.3 0 0 0 .948-.81c.184-.187.354-.377.513-.56L6.125 1H1v3h4.416c.016.01.255-.05.545-.25z"
                        />
                        <g fill="currentColor" transform="translate(-1, 6)">
                          <path d="m3.5 5c1.38071 0 2.5-1.11929 2.5-2.5s-1.11929-2.5-2.5-2.5-2.5 1.11929-2.5 2.5 1.11929 2.5 2.5 2.5z" />
                          <path d="m2 6c-1.104569 0-2 .89543-2 2v1h7v-1c0-1.10457-.89543-2-2-2z" />
                        </g>
                      </svg>
                      <svg
                        id="folder-open-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 15 16"
                        width={17}
                        height={16}
                      >
                        <g mask="none">
                          <path
                            fill="currentColor"
                            d="M5.26 3l1.28 1.368.594.632H12v6H3V3h2.26m.865-2H1v12h13V3H8L6.125 1z"
                          />
                          <path
                            fill="currentColor"
                            d="M1 7v6h13V9H8L6.125 7z"
                          />
                        </g>
                      </svg>
                      <svg
                        id="folder-open-protected-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 15 16"
                        width={17}
                        height={16}
                      >
                        <mask id="folder-open-protected-icon-lock-badge-mask">
                          <rect
                            fill="white"
                            x={0}
                            y={0}
                            width="100%"
                            height="100%"
                          />
                          <path
                            transform="translate(-3, 4)"
                            fill="black"
                            d="M10 5v8H0V5a5 5 0 0 1 10 0z"
                          />
                        </mask>
                        <g mask="url(#folder-open-protected-icon-lock-badge-mask)">
                          <path
                            fill="currentColor"
                            d="M5.26 3l1.28 1.368.594.632H12v6H3V3h2.26m.865-2H1v12h13V3H8L6.125 1z"
                          />
                          <path
                            fill="currentColor"
                            d="M1 7v6h13V9H8L6.125 7z"
                          />
                        </g>
                        <g transform="translate(-3, 4)">
                          <path fill="#E6BE49" d="M2 7h6v4H2z" />
                          <path
                            fill="#D6D6D6"
                            d="M8 6V5a3 3 0 0 0-6 0v1h1.1V5a1.9 1.9 0 0 1 3.8 0v1H8z"
                          />
                        </g>
                      </svg>
                      <svg
                        id="folder-open-ancestor-protected-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 15 16"
                        width={17}
                        height={16}
                      >
                        <mask id="folder-open-ancestor-protected-icon-lock-badge-mask">
                          <rect
                            fill="white"
                            x={0}
                            y={0}
                            width="100%"
                            height="100%"
                          />
                          <path
                            transform="translate(-3, 4)"
                            fill="black"
                            d="M10 5v8H0V5a5 5 0 0 1 10 0z"
                          />
                        </mask>
                        <g mask="url(#folder-open-ancestor-protected-icon-lock-badge-mask)">
                          <path
                            fill="currentColor"
                            d="M5.26 3l1.28 1.368.594.632H12v6H3V3h2.26m.865-2H1v12h13V3H8L6.125 1z"
                          />
                          <path
                            fill="currentColor"
                            d="M1 7v6h13V9H8L6.125 7z"
                          />
                        </g>
                        <g transform="translate(-3, 4)">
                          <path fill="currentColor" d="M2 7h6v4H2z" />
                          <path
                            fill="currentColor"
                            d="M8 6V5a3 3 0 0 0-6 0v1h1.1V5a1.9 1.9 0 0 1 3.8 0v1H8z"
                          />
                        </g>
                      </svg>
                      <svg
                        id="folder-open-members-only-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 15 16"
                        width={17}
                        height={16}
                      >
                        <mask id="folder-open-members-only-icon-member-badge-mask">
                          <rect
                            fill="white"
                            x={0}
                            y={0}
                            width="100%"
                            height="100%"
                          />
                          <path
                            transform="translate(-3, 4)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 10V13H11V10C11 8.68166 10.3622 7.51228 9.37837 6.78357C9.77337 6.11415 10 5.33354 10 4.5C10 2.01472 7.98528 0 5.5 0C3.01472 0 1 2.01472 1 4.5C1 5.33354 1.22663 6.11415 1.62163 6.78357C0.637782 7.51228 0 8.68166 0 10Z"
                            fill="black"
                          />
                        </mask>
                        <g mask="url(#folder-open-members-only-icon-member-badge-mask)">
                          <path
                            fill="currentColor"
                            d="M5.26 3l1.28 1.368.594.632H12v6H3V3h2.26m.865-2H1v12h13V3H8L6.125 1z"
                          />
                          <path
                            fill="currentColor"
                            d="M1 7v6h13V9H8L6.125 7z"
                          />
                        </g>
                        <g fill="#5ea6f7" transform="translate(-1, 6)">
                          <path d="m3.5 5c1.38071 0 2.5-1.11929 2.5-2.5s-1.11929-2.5-2.5-2.5-2.5 1.11929-2.5 2.5 1.11929 2.5 2.5 2.5z" />
                          <path d="m2 6c-1.104569 0-2 .89543-2 2v1h7v-1c0-1.10457-.89543-2-2-2z" />
                        </g>
                      </svg>
                      <svg
                        id="folder-open-ancestor-members-only-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 0 15 16"
                        width={17}
                        height={16}
                      >
                        <mask id="folder-open-ancestor-members-only-icon-member-badge-mask">
                          <rect
                            fill="white"
                            x={0}
                            y={0}
                            width="100%"
                            height="100%"
                          />
                          <path
                            transform="translate(-3, 4)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 10V13H11V10C11 8.68166 10.3622 7.51228 9.37837 6.78357C9.77337 6.11415 10 5.33354 10 4.5C10 2.01472 7.98528 0 5.5 0C3.01472 0 1 2.01472 1 4.5C1 5.33354 1.22663 6.11415 1.62163 6.78357C0.637782 7.51228 0 8.68166 0 10Z"
                            fill="black"
                          />
                        </mask>
                        <g mask="url(#folder-open-ancestor-members-only-icon-member-badge-mask)">
                          <path
                            fill="currentColor"
                            d="M5.26 3l1.28 1.368.594.632H12v6H3V3h2.26m.865-2H1v12h13V3H8L6.125 1z"
                          />
                          <path
                            fill="currentColor"
                            d="M1 7v6h13V9H8L6.125 7z"
                          />
                        </g>
                        <g fill="currentColor" transform="translate(-1, 6)">
                          <path d="m3.5 5c1.38071 0 2.5-1.11929 2.5-2.5s-1.11929-2.5-2.5-2.5-2.5 1.11929-2.5 2.5 1.11929 2.5 2.5 2.5z" />
                          <path d="m2 6c-1.104569 0-2 .89543-2 2v1h7v-1c0-1.10457-.89543-2-2-2z" />
                        </g>
                      </svg>
                    </defs>
                  </svg>
                  <style
                    dangerouslySetInnerHTML={{
                      __html: "* { box-sizing: border-box; }",
                    }}
                  />
                  <div
                    className="wf-81ocvw"
                    data-wf-size-context="large"
                    style={{ display: "contents" }}
                  />
                  <div className="w-editor-bem-EditorApp w-editor-bem-EditorApp-login w-editor-bem-EditorApp-minimized">
                    <div
                      style={{ zIndex: 2147483547 }}
                      className="w-reset w-editor-bem-EditorApp_Main"
                    >
                      <style
                        dangerouslySetInnerHTML={{
                          __html: "* { box-sizing: border-box; }",
                        }}
                      />
                      <div
                        className="wf-81ocvw"
                        data-wf-size-context="large"
                        style={{ display: "contents" }}
                      />
                      <div className="w-reset w-editor-bem-EditorOverlay">
                        <span />
                        <div
                          className="w-editor-rich-text-container"
                          style={{ top: "0px", left: "0px" }}
                        >
                          <div id="w-rich-text-node-editor" />
                        </div>
                      </div>
                      <div className="w-reset w-editor-bem-EditorBlocker">
                        <div className="w-reset w-editor-bem-EditorBlocker_Positioner">
                          <div className="w-reset w-editor-bem-EditorBlocker_Button w-editor-scrollbar-offset">
                            <i
                              style={{ bottom: "1px" }}
                              className="w-reset w-editor-bem-Icon  w-editor-icon-undefined w-editor-undefined"
                            />
                            <span className="w-reset w-editor-bem-Text ">
                              Back to Live Site
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        className="w-reset w-editor-bem-EditorApp_Panel"
                      >
                        <div className="w-reset w-editor-bem-EditorStartButton">
                          <div
                            className="w-reset w-editor-bem-EditorStartButton_Shape"
                            style={{
                              transform: "translateY(12px)",
                              transition:
                                "transform 400ms cubic-bezier(0.23, 1, 0.32, 1)",
                            }}
                          />
                          <i
                            className="w-reset w-editor-bem-Icon  w-editor-icon-dynamo w-editor-chevron-up w--arrow"
                            style={{
                              transform: "translateY(0px)",
                              transition:
                                "opacity 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1)",
                              opacity: "0.5",
                            }}
                          />
                          <i
                            className="w-reset w-editor-bem-Icon  w-editor-icon-dynamo w-editor-edit-text w--pencil"
                            style={{ opacity: 0, transform: "translateY(7px)" }}
                          />
                        </div>
                      </div>
                      <div className="w-reset w-editor-bem-EditorApp_Tooltips" />
                    </div>
                  </div>
                  <div
                    style={{
                      position: "fixed",
                      top: "0px",
                      left: "0px",
                      width: "100%",
                      zIndex: 2147483547,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
