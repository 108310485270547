import { useState, useEffect, FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "./Auth";
import Cookies from "universal-cookie";
import type { FormProps } from "antd";
import { Button, Checkbox, Form, Input, Flex, Segmented } from "antd";
import type { FlexProps, SegmentedProps } from "antd";
import "./SignIn.scss";
import { faHandMiddleFinger } from "@fortawesome/free-solid-svg-icons";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../../config";

export default function SignIn() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const cookie = new Cookies(null, { path: "/" });

  useEffect(() => {
    document.body.classList.add("dashboard-body");
    const cookie = new Cookies(null, { path: "/" });
    cookie.remove("session_id");
    document.cookie =
      "session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }, []);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const values = {
      username: formData.get("username") as string,
      password: formData.get("password") as string,
    };

    const formDataLast = new URLSearchParams();
    formDataLast.append("username", values.username);
    formDataLast.append("password", values.password);
    formDataLast.append("grant_type", "password");

    fetch(`${BASE_URL}/api/v1/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formDataLast,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "error") {
          if (data.msg === "Email not verified") {
            navigate("/accountdisable");
          } else {
            setErrorMessage(data.msg);
          }
        } else {
          cookie.set("access_token", data.access_token);
          navigate("/dashboard/vostests");
          navigate(0);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage("Invalid username or password");
      });
  };

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
          Connexion
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form
          className="space-y-6"
          action="#"
          method="POST"
          onSubmit={handleSubmit}
        >
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-slate-400"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                id="username"
                name="username"
                type="email"
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-slate-400"
              >
                Mot de passe
              </label>
              <div className="text-sm">
                <a
                  onClick={() => navigate("/forgot-password")}
                  className="signin-link font-semibold cursor-pointer"
                >
                  Mot de passe oublié ?
                </a>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="con-button flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Se connecter
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Vous n'êtes pas membre ?
          <a
            onClick={() => navigate("/signup")}
            className=" signin-link font-semibold leading-6 cursor-pointer"
          >
            Créer un compte
          </a>
        </p>
      </div>
    </div>
  );
}
