import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable, DroppableProvided, DroppableStateSnapshot, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

type Technology = {
  [key: number]: string;
};
type Exercise = {
content: string;
difficulty: number;
name: string;
id: number;
rdm_id: number;
estimated_time: number;
technologies: Technology;
is_public: boolean;
};

type Result = {
  draggableId: string;
  type?: string;
  source: {
    droppableId: string;
    index: number;
  };
  destination?: {
    droppableId: string;
    index: number;
  } | null;
  mode?: 'FLUID' | 'SNAP';
  reason?: 'DROP' | 'CANCEL';
};

type DraggableListProps = {
    items: Exercise[];
    onMinusButtonClick: (exercise: Exercise) => void;
    handleDragEnd: (newOrder: Exercise[]) => void;
  };

const DraggableList: React.FC<DraggableListProps> = ({ items, onMinusButtonClick, handleDragEnd }) => {
    const [listItems, setListItems] = useState<Exercise[]>(items);

    useEffect(() => {
        setListItems(items);
      }, [items]);

    const onDragEnd = (result: Result) => {
        if (!result.destination) return; // dropped outside the list

        const newListItems = Array.from(listItems);
        const [reorderedItem] = newListItems.splice(result.source.index, 1);
        newListItems.splice(result.destination.index, 0, reorderedItem);

        setListItems(newListItems);
        handleDragEnd(newListItems);
    };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {listItems.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <div className="exercicebloc2" key={index} ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <div className="div-block-47">
                      <img
                        src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65d319b91e0745da1fabf16b_ellipsis-vertical-solid.svg"
                        loading="lazy"
                        width={8}
                        alt=""
                      />
                      <div className="text-block-32">
                        {item.name}
                      </div>
                    </div>
                    <button className="plus-exercise-button" onClick={() => onMinusButtonClick(item)}>
                      <img
                        src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65d24fa4c71e84a616b97106_xmark-solid(1).svg"
                        loading="lazy"
                        width={15}
                        alt=""
                      />
                      </button>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
