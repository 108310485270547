import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

interface Props {
    handleButtonClick: (buttonId: string) => void;
  };

export const MyDropdown: React.FC<Props> = ({ handleButtonClick }) => {
    return (
      <div>
        <Menu as="div">
          <div>
            <Menu.Button className="buttonfiltre w-button">
              Filtre
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="menu-items">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                    id="privateButton"
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                      } menu-item`}
                      onClick= {() => handleButtonClick('Aws')}
                    >
                      Aws
                    </button>
                  )}
                </Menu.Item>
              </div>
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      id="terraformButton"
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                      } menu-item`}
                      onClick= {() => handleButtonClick('Terraform')}
                    >
                      Terraform
                    </button>
                  )}
                </Menu.Item>
              </div>
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      id="ansibleButton"
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                      } menu-item`}
                      onClick= {() => handleButtonClick('Ansible')}
                    >
                      Ansible
                    </button>
                  )}
                </Menu.Item>
              </div>
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      id="dockerButton"
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                      } menu-item`}
                      onClick= {() => handleButtonClick('Docker')}
                    >
                      Docker
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    )
  };