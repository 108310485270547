import React,{ useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input, Flex, Segmented, Row, Col } from 'antd';
import "./SearchDevops.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Menu, Transition, Dialog } from '@headlessui/react';
import Cookies from 'universal-cookie';

const style: React.CSSProperties = { background: '#0092ff', padding: '8px 0' };

type Technology = {
    [key: number]: string;
};

type Devops = {
    id: number;
    tjm: number;
    salary: number;
    xp: number;
    name: string;
    firstname: string;
    email: string;
    technologies: Technology;
  };

interface DevopsCardProps {
    devops: Devops;
}

interface Props {
    handleButtonClick: (buttonId: string) => void;
}

const DevopsCard: React.FC<DevopsCardProps> = ({ devops }) => {
    return (
      <div className="card">
        <div className="card-content">
          <div className="card-info">
            <h2>{devops.firstname} {devops.name}</h2>
            <p>{devops.email}</p>
            <p><strong>ID:</strong> {devops.id}</p>
            <p><strong>TJM:</strong> {devops.tjm} €</p>
            <p><strong>Salary:</strong> {devops.salary} €</p>
            <p><strong>Experience:</strong> {devops.xp} years</p>
          </div>
          <div className="card-technologies">
            <strong>Technologies:</strong>
            <ul>
              {Object.entries(devops.technologies).map(([key, tech]) => (
                <li key={key}>{tech}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

const MyDropdown: React.FC<Props> = ({ handleButtonClick }) => {
    return (
      <div>
        <Menu as="div">
          <div>
            <Menu.Button className="buttonfiltre w-button">
              Filtre
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="menu-items">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                    id="privateButton"
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                      } menu-item`}
                      onClick= {() => handleButtonClick('Aws')}
                    >
                      Aws
                    </button>
                  )}
                </Menu.Item>
              </div>
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      id="terraformButton"
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                      } menu-item`}
                      onClick= {() => handleButtonClick('Terraform')}
                    >
                      Terraform
                    </button>
                  )}
                </Menu.Item>
              </div>
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      id="ansibleButton"
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                      } menu-item`}
                      onClick= {() => handleButtonClick('Ansible')}
                    >
                      Ansible
                    </button>
                  )}
                </Menu.Item>
              </div>
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      id="dockerButton"
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                      } menu-item`}
                      onClick= {() => handleButtonClick('Docker')}
                    >
                      Docker
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    )
  };


export default function SearchDevops() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filtersList, setFiltersList] = useState<string[]>([]);
  const [showedDevops, setShowedDevops] = useState<Devops[]>([]);
  const cookie = new Cookies(null, {path: '/'});

  const devopsData: Devops = {
    id: 1,
    tjm: 500,
    salary: 60000,
    xp: 5,
    name: 'Doe',
    firstname: 'John',
    email: 'john.doe@example.com',
    technologies: {
      1: 'Docker',
      2: 'Kubernetes',
      3: 'AWS',
      4: 'Terraform'
    }
  };

  useEffect(() => {
    document.body.classList.add('dashboard-body');
    fetchDevops();
  }, []);

  const fetchDevops = async () => {
    try {
      const response = await fetch('https://app.calinfra.fr/api/v1/devops', {
        method: 'GET', // or 'GET' depending on your backend route configuration
        headers: {
          'Authorization': `Bearer ${cookie.get('access_token')}`,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setShowedDevops(data);
      // Do something with the response data, such as updating state
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleFilterAddClick = (buttonId: string) => {
    // Check if the exercise is already in the selected exercises list
    const isAlreadySelected = filtersList.some(selectedTech => selectedTech === buttonId);

    if (!isAlreadySelected) {
      // Add the exercise to the selected exercises list
      setFiltersList(prevElements => [...prevElements, buttonId]);
    };
 };

 const handleFilterRemoveClick = (buttonId: string) => {
    const updatedFilters = filtersList.filter(selectedFilters => selectedFilters !== buttonId);
    setFiltersList(updatedFilters);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Flex gap="middle" vertical>
        <Flex justify="center" align="center">
            <p className="p-devops">Page de recherche Devops</p>
        </Flex>
        <Flex justify="center" align="center">
            <div className="w-layout-blockcontainer containertestsearch w-container">
              <div className="w-form">
                
                  <div className="w-layout-blockcontainer container-16 w-container">
                    <input
                      className="text-field-4 w-input"
                      maxLength={256}
                      name="TestName"
                      data-name="TestName"
                      placeholder="Recherchez votre exercice"
                      type="text"
                      id="TestName"
                      value={searchTerm}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="w-layout-blockcontainer container-17 w-container">
                    <MyDropdown handleButtonClick={handleFilterAddClick}/>
                        {filtersList.map((element, index) => (
                          <div className="div-block-11" style={{ alignSelf: 'start' }}>
                            <div className="text-block-25">{element}</div>
                            <button className={ 'button-3 w-button' } onClick={() => handleFilterRemoveClick(element)} >
                              <FontAwesomeIcon icon={solid("xmark")} style={{ display: "block", fontSize: "12px" }}/>
                            </button>
                          </div>
                        ))}
                  </div>

              </div>
              <div className="div-block-9">
                { showedDevops.length > 0 ? (showedDevops.map((item, index) => (
                  <div className="div-block-16" key={index}>
                    <div className="text-block-28">{item.name}</div>
                  </div>
                ))) : (<div> </div>)}
              </div>
            </div>
        </Flex>
        <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={6}>
                <DevopsCard devops={devopsData}/>
            </Col>
        </Row>
    </Flex>
  );
}