import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import "./AccountEnable.scss"
import { BASE_URL } from "../../../config";

export default function AccountEnable() {
  const {verificationId} = useParams<{ verificationId: string}>();
  const [accountEnable, setAccountEnable] = useState(false);

  useEffect(() => {
    enableAccount();
  }, []);

  const enableAccount = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/verification-email/${verificationId}`, {
        method: 'GET', // or 'GET' depending on your backend route configuration
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.response === "ok"){
        setAccountEnable(true);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen pt-[5%] font-montserrat text-lg">
      <div className="text-center p-8 w-[80%] min-w-[300px] max-w-[600px] mx-auto">
        <h3 className="text-3xl font-bold text-gray-100 mb-8">Vérification du compte</h3>
        {accountEnable ? (
          <p className="text-xl text-gray-300 mb-6">
            Votre compte est bien vérifié ! Vous pouvez maintenant vous connecter.
          </p>
        ) : (
          <p className="text-xl text-gray-300 mb-6">
            Il y a un souci avec la vérification de votre compte, veuillez
            contacter un administrateur en envoyant un email à calinfra.enterprise@gmail.com.
          </p>
        )}
      </div>
      {accountEnable && (
        <button 
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mb-8"
          onClick={() => window.location.href = '/signin'}
        >
          Se Connecter
        </button>
      )}
    </div>
  );
}