import { BASE_URL } from "../../../config";

export const getCourseList = async () => {
  try {
    const response = await fetch(BASE_URL + "/courses");
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
