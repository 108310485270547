import React, { useState, useEffect, FormEventHandler, Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";
import "./Account.scss";
import { useAuth } from "../../../Auth/src/pages/Auth";
import DraggableList from "../../../Common/components/DraggableList";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { BASE_URL } from "../../../config";

type Info = {
  firstname: string;
  name: string;
  email: string;
  company: string;
  credits: number;
  creation_date: string;
};

export default function Account() {
  const cookie = new Cookies(null, { path: "/" });
  const [infos, setInfos] = useState<Info>();
  const navigate = useNavigate();

  const [isEditingName, setIsEditingName] = useState(false);
  const [name, setName] = useState(infos ? infos.name : "");
  const [isEditingFirstname, setIsEditingFirstname] = useState(false);
  const [firstname, setFirstname] = useState(infos ? infos.firstname : "");
  const [isEditingCompany, setIsEditingCompany] = useState(false);
  const [company, setCompany] = useState(infos ? infos.company : "");

  useEffect(() => {
    document.body.classList.add("dashboard-body");
    fetchUserInfo();
  }, []);

  const handleEditName = () => {
    setIsEditingName(true); // Enable editing mode
  };

  const handleCancelName = () => {
    setName(infos?.name || ""); // Reset the input to original value
    setIsEditingName(false); // Disable editing mode
  };

  const handleSaveName = async () => {
    // Here you would usually send the updated name to your backend or state
    setIsEditingName(false); // Disable editing mode after saving

    await ChangeName();
  };

  const handleEditFirstname = () => {
    setIsEditingFirstname(true); // Enable editing mode
  };

  const handleCancelFirstname = () => {
    setFirstname(infos?.firstname || ""); // Reset the input to original value
    setIsEditingFirstname(false); // Disable editing mode
  };

  const handleSaveFirstname = async () => {
    // Here you would usually send the updated name to your backend or state
    setIsEditingFirstname(false); // Disable editing mode after saving

    await ChangeFirstname();
  };

  const handleEditCompany = () => {
    setIsEditingCompany(true); // Enable editing mode
  };

  const handleCancelCompany = () => {
    setCompany(infos?.company || ""); // Reset the input to original value
    setIsEditingCompany(false); // Disable editing mode
  };

  const handleSaveCompany = async () => {
    // Here you would usually send the updated name to your backend or state
    setIsEditingCompany(false); // Disable editing mode after saving

    await ChangeCompany();
  };
  const fetchUserInfo = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/user/info`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 401) {
        navigate("/unauthorized");
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const information = data as Info;
      setInfos(information);
      setName(information.name);
      setFirstname(information.firstname);
      setCompany(information.company);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const ChangeFirstname = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/user/firstname`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          new_firstname: firstname, // Pass the new name in the body
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const ChangeName = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/user/name`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          new_name: name, // Pass the new name in the body
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const ChangeCompany = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/user/company`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          new_company: company, // Pass the new name in the body
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex items-center justify-center">
      <div className="flex-col xl:px-24 p-14 xl:mx-24 md:w-8/12 xl:w-6/12 justify-center items-center">
        <h4 className="text-white">Information du compte :</h4>
        <div className="div-block-58 mt-12 p-8">
          <div className="flex gap-10">
            <div className="grid-account grid-rows-5 flex-1 gap-4 basis-1/4 xl:gap-6">
              {/* <div className="text-slate-300 text-nowrap">Date de création :</div> */}
              <div className="text-slate-300">Nom :</div>
              <div className="text-slate-300">Prénom :</div>
              <div className="text-slate-300">Email :</div>
              <div className="text-slate-300">Crédits :</div>
              <div className="text-slate-300">Entreprise :</div>
            </div>
            <div className="grid-account grid-rows-5 flex-1 basis-3/4 gap-4 xl:gap-6">
              {/* <div className="text-slate-300 text-right">
                  {infos ? infos.creation_date : "-"}
                </div> */}
              <div className="flex flex-wrap items-center justify-end gap-6">
                {!isEditingName ? (
                  <>
                    <div className="text-slate-300">
                      {name ? name : "-"}
                    </div>
                    <button
                      className="edit-info-button"
                      onClick={handleEditName}
                    >
                      <FontAwesomeIcon
                        icon={solid("pencil")}
                        style={{
                          display: "block",
                          fontSize: "20px",
                          color: "#1ed760",
                        }}
                      />
                    </button>
                  </>
                ) : (
                  <div className="flex gap-x-2">
                    <input
                      type="text"
                      className="border border-slate-300 text-black p-2 rounded"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <button
                      className="bg-green-500 text-white px-2 py-2 rounded hover:bg-green-600"
                      onClick={handleSaveName}
                    >
                      <FontAwesomeIcon
                        icon={solid("check")}
                        style={{
                          display: "block",
                          fontSize: "20px",
                          color: "#ffffff",
                        }}
                      />
                    </button>
                    <button
                      className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600"
                      onClick={handleCancelName}
                    >
                      <FontAwesomeIcon
                        icon={solid("xmark")}
                        style={{
                          display: "block",
                          fontSize: "20px",
                          color: "#ffffff",
                        }}
                      />
                    </button>
                  </div>
                )}
              </div>
              <div className="flex flex-wrap items-center justify-end gap-6">
              {!isEditingFirstname ? (
                  <>
                    <div className="text-slate-300">
                      {firstname ? firstname : "-"}
                    </div>
                    <button
                      className="edit-info-button"
                      onClick={handleEditFirstname}
                    >
                      <FontAwesomeIcon
                        icon={solid("pencil")}
                        style={{
                          display: "block",
                          fontSize: "20px",
                          color: "#1ed760",
                        }}
                      />
                    </button>
                  </>
                ) : (
                  <div className="flex gap-x-2">
                    <input
                      type="text"
                      className="border border-slate-300 text-black p-2 rounded"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                    <button
                      className="bg-green-500 text-white px-2 py-2 rounded hover:bg-green-600"
                      onClick={handleSaveFirstname}
                    >
                      <FontAwesomeIcon
                        icon={solid("check")}
                        style={{
                          display: "block",
                          fontSize: "20px",
                          color: "#ffffff",
                        }}
                      />
                    </button>
                    <button
                      className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600"
                      onClick={handleCancelFirstname}
                    >
                      <FontAwesomeIcon
                        icon={solid("xmark")}
                        style={{
                          display: "block",
                          fontSize: "20px",
                          color: "#ffffff",
                        }}
                      />
                    </button>
                  </div>
                )}
              </div>
              <div className="flex flex-wrap items-center justify-end gap-6">
                <div className="text-slate-300 text-right">
                  {infos ? infos.email : "-"}
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-end gap-6">
                <div className="text-slate-300">
                  {infos ? infos.credits : "-"}
                </div>
                <FontAwesomeIcon
                  icon={solid("coins")}
                  style={{
                    display: "block",
                    fontSize: "20px",
                    color: "#787878",
                  }}
                />
              </div>
              <div className="flex flex-wrap items-center justify-end gap-6">
              {!isEditingCompany ? (
                  <>
                    <div className="text-slate-300">
                      {company ? company : "-"}
                    </div>
                    <button
                      className="edit-info-button"
                      onClick={handleEditCompany}
                    >
                      <FontAwesomeIcon
                        icon={solid("pencil")}
                        style={{
                          display: "block",
                          fontSize: "20px",
                          color: "#1ed760",
                        }}
                      />
                    </button>
                  </>
                ) : (
                  <div className="flex gap-x-2">
                    <input
                      type="text"
                      className="border border-slate-300 text-black p-2 rounded"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <button
                      className="bg-green-500 text-white px-2 py-2 rounded hover:bg-green-600"
                      onClick={handleSaveCompany}
                    >
                      <FontAwesomeIcon
                        icon={solid("check")}
                        style={{
                          display: "block",
                          fontSize: "20px",
                          color: "#ffffff",
                        }}
                      />
                    </button>
                    <button
                      className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600"
                      onClick={handleCancelCompany}
                    >
                      <FontAwesomeIcon
                        icon={solid("xmark")}
                        style={{
                          display: "block",
                          fontSize: "20px",
                          color: "#ffffff",
                        }}
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="w2-layout-blockcontainer container-32 w-container">
          <a href="#" className="button-15 w2-button">
            Supprimez votre compte
          </a>
        </div> */}
      </div>
    </div>
  );
}
