import React, { useState, useEffect } from 'react';
import { useParams, useNavigate} from "react-router-dom";

interface Result {
  score: number; // Remplacez 'number' par le type réel de la propriété 'score'
  // Ajoutez d'autres propriétés au besoin
}

const ResultFreeTest = () => {
  const [result, setResult] = useState<Result | null>(null); // Utilisez l'interface Result
  const {technoName} = useParams<{ technoName: string}>();
  const navigate = useNavigate(); // Importez useNavigate

  useEffect(() => {
    // Remplacez l'URL de l'API par celle que vous souhaitez utiliser
    const apiUrl = `https://app.calinfra.fr/api/v1/test/calculScore/${technoName}`;

    // Effectue la requête à l'API
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => setResult(data))
      .catch(error => console.error('Erreur lors de la récupération des données :', error));
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      {result ? (
        <>
          <h1>Résultat de votre test {technoName}</h1>
          <p>Score : {result.score} %</p>
        </>
      ) : (
        <p>Chargement de votre score en cours...</p>
      )}
      <p>Résumé :</p>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <button className='btn-pricing' onClick={() => navigate('/pricing')}>
          S'abonner
        </button>
        <button className='btn-home' onClick={() => navigate('/')}>Accueil</button>
      </div>
    </div>
  );
};

export default ResultFreeTest;