import React, { useEffect, useState } from "react";
import Button from "../../../Common/components/Button";
import SideBar from "../../../Common/components/SideBar";
import { getCourseList } from "../../../Common/services/courses/courses.services";
import { Course } from "../../../Common/types/Course";
import "./CourseList.scss";

export default function CourseList() {
  const [courses, setCourses] = useState([]);

  const initCourses = async () => {
    const data = await getCourseList();
    console.log(data);
    setCourses(data);
  };

  useEffect(() => {
    initCourses();
  }, []);

  console.log(courses);

  return (
    <div className='container'>
      <SideBar />
      <div className='content'>
        <div className='courses-list'>
          <h3>Vos cours</h3>

          <div className='courses'>
            {courses.map((course: Course) => {
              return (
                <div className='course' key={course.course_id}>
                  <div className='course-left'>
                    <div>
                      <img src='https://picsum.photos/150' />
                    </div>
                    <div className='course-details'>
                      <h4>{course.course_name}</h4>
                      <p>{course.end_date}</p>
                    </div>
                  </div>
                  <div className='course-right'>
                    <Button>Accéder</Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className='recommandations-list'>
          <h3>Recommandations</h3>

          <div className='recommandations'>
            <div className='recommandation'>
              <div className='recommandation-image'>
                <img src='https://picsum.photos/150' alt='' />
              </div>
              <div className='recommandation-details'>
                <h4>Docker pour les nuls</h4>
                <p>99.00€</p>
              </div>
              <div className='btn-group'>
                <Button classes={["btn-white"]}>Voir plus</Button>
                <Button classes={["btn-white"]}>Acheter</Button>
              </div>
            </div>
            <div className='recommandation'>
              <div className='recommandation-image'>
                <img src='https://picsum.photos/150' alt='' />
              </div>
              <div className='recommandation-details'>
                <h4>Kubernets pour les nuls</h4>
                <p>399.00€</p>
              </div>
              <div className='btn-group'>
                <Button classes={["btn-white"]}>Voir plus</Button>
                <Button classes={["btn-white"]}>Acheter</Button>
              </div>
            </div>
            <div className='recommandation'>
              <div className='recommandation-image'>
                <img src='https://picsum.photos/150' alt='' />
              </div>
              <div className='recommandation-details'>
                <h4>AWS pour les nuls</h4>
                <p>99.00€</p>
              </div>
              <div className='btn-group'>
                <Button classes={["btn-white"]}>Voir plus</Button>
                <Button classes={["btn-white"]}>Acheter</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
