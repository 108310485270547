import Navbar from "../../../Common/components/Navbar";
import { useEffect, useState, useRef, FC, useCallback } from "react";
import "xterm/css/xterm.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import ExerciseHeader from "../../../Common/components/ExerciseHeader";
import Button from "../../../Common/components/Button";
import ButtonNext from "../../../Common/components/ButtonNext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, useAnimationControls } from "framer-motion";
import validation from "../../../Common/assets/validation.png";
import "./SingleExercise.scss";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";

declare global {
  interface Window {
    xtermSocket:any;
  }
}

function ProgressBar() {
  const {lessonId, exerciseId} = useParams<{ lessonId: string, exerciseId: string}>();
  const [scrollPercentage, setScrollPercentage] = useState((Number(exerciseId) * 100) / 7);
  const [numExercises, setNumExercises] = useState(0);
  useEffect(() => {
    setScrollPercentage((Number(exerciseId) * 100) / numExercises);
  }, [exerciseId, numExercises]);

  useEffect(() => {
    // Fetch number of exercises for the lesson
    fetch(`https://app.calinfra.fr/exercises/number/${lessonId}`)
      .then(response => response.json())
      .then(data => setNumExercises(data.number))
      .catch(error => console.error(error));
  }, [lessonId]);

  return (
    <div className='progress-bar'>
      <div style={{ width: '50%' }}></div>
      <div style={{ display: 'flex', width: '50%', alignItems: 'center'}}>
        <div style={{ float: 'right', marginRight: '20px' }}>Progression</div>
        <div style={{ height: '10px', width: '50%', backgroundColor: '#ccc', float: 'right' }}>
        <div style={{ height: '100%', backgroundColor: '#007bff', width: `${scrollPercentage}%` }} />
      </div>
    </div>
    </div>
  );
}

function IframeComponent() {
  const iframeRef = useRef(null);
  const {lessonId, exerciseId} = useParams<{ lessonId: string, exerciseId: string}>();
  const src = `https://app.calinfra.fr/terminalVim/${exerciseId}`
  return (
    <iframe
      frameBorder="0"
      id="iframe"
      ref={iframeRef}
      src={src}
      width='100%'
      height='100%'
    />
  );
}

export default function SingleExercise() {
  const [showIframe, setShowIframe] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [rotate, setRotate] = useState(false);
  const controls = useAnimationControls()
  const {lessonId, exerciseId} = useParams<{ lessonId: string, exerciseId: string}>();
  const navigate = useNavigate();

  const [exerciseText, setExerciseText] = useState({content: ""});

  const NextStep = async() => {
    const incrementedParam = parseInt(exerciseId!, 10) + 1; // add 1 to the integer
    const iframe = document.querySelector("iframe");
    if (iframe && iframe.contentWindow && iframe.contentWindow.xtermSocket) {
      const socketTerminal = iframe.contentWindow.xtermSocket;
      socketTerminal.close();
    }
    await navigate(`/exercise/0/${incrementedParam}`);
  };

  const PreviousStep = async() => {
    const iframe = document.querySelector("iframe");
    if (iframe && iframe.contentWindow && iframe.contentWindow.xtermSocket) {
      const socketTerminal = iframe.contentWindow.xtermSocket;
      socketTerminal.close();
    }
    await navigate(-1);
  }
  
  const Retry = async() => {
    const iframe = document.querySelector("iframe");
    if (iframe && iframe.contentWindow && iframe.contentWindow.location && iframe.contentWindow.xtermSocket) {
      const socketTerminal = iframe.contentWindow.xtermSocket;
      await socketTerminal.close();
      iframe.contentWindow.location.reload();
      setShowIframe(true);
    }
  }

  useEffect(() => {
    const waitForIframeLoad = () => {
      const iframe = document.querySelector("iframe");
      if (iframe && iframe.contentWindow && iframe.contentWindow.xtermSocket) {
        const socketTerminal = iframe.contentWindow.xtermSocket;
        socketTerminal.onopen = () => {
        };
  
        socketTerminal.onmessage = (event: any) => {
          const decoder = new TextDecoder("utf-8");
          const message = decoder.decode(event.data);
          //const incrementedParam = parseInt(exerciseId!, 10) + 1;
          if (message.includes(`exo${exerciseId}/success`)) {
            //setButtonVisibility(true);
            setShowIframe(false);
            controls.start({opacity: 1, transition: {duration: 3}})
          }
        };
        
        return () => {
          socketTerminal.close();
        };
      }
      setTimeout(waitForIframeLoad, 1000);
    };
  
    waitForIframeLoad();
  }, [lessonId,exerciseId,showIframe]);

  useEffect(() => {
    setShowIframe(true);
    controls.start({ opacity: 0 });
    fetch(`https://app.calinfra.fr/exercises/0/${exerciseId}`)
    .then(response => {
      if (response.status === 401 || response.status === 422) {
        // Navigate to another page if the response status is 401 Unauthorized
        navigate("/signin");
        return ""
      } 
      else {
        return response.text();
      }
    })
    .then(text => JSON.parse(text))
    .then(data => setExerciseText(data))
    .catch(error => console.error(error));
  }, [lessonId, exerciseId]);

  const [exerciseList, setExerciseList] = useState([]);

  useEffect(() => {
    // Fetch exercise list from backend
    fetch(`https://app.calinfra.fr/exercises/${lessonId}`)
      .then(response => response.json())
      .then(data => {
        const sortedExercises = data.sort((a: any, b: any) => a.idExercise - b.idExercise);
        setExerciseList(sortedExercises);
      })
      .catch(error => console.error(error));
  }, [lessonId]);

  return (
    <div className='code'>
      <ProgressBar />
      <div className={`sidebar-exercise ${showSidebar ? 'active' : ''}`}>
        <div style={{ padding: "20px" }}>
          <h2>Cours sur Vim</h2>
        </div>
        <nav className="side-chapters">
          {exerciseList.map((exercise: any) => (
            <button className='side-chapters-button' onClick={() => {navigate(`/exercise/0/${exercise.idExercise}`); setShowSidebar(!showSidebar); setRotate(!rotate);}}>Exo{exercise.idExercise} : {exercise.title}</button>
          ))}
        </nav>
        <button className="sidebar-exercise-toggle" onClick={() => {setShowSidebar(!showSidebar); setRotate(!rotate);}}>
          <FontAwesomeIcon icon={solid("angles-right")} style={{ fontSize: "24px", float: "right", marginRight: '7px' }} className={rotate ? "rotate active" : "rotate"} />
        </button>
      </div>
      <ExerciseHeader />
      <div className='content'>
        <div className='exercise'>
          <div className='row exercise-content'>
            <div className='subject' dangerouslySetInnerHTML={{__html: exerciseText.content}}>
            </div>
            <div className="ide center">
            {showIframe && <IframeComponent />}
            {!showIframe && <img src={validation} alt="success!" style={{ width: "10%", height: "auto" }} />}
            </div>
          </div>
          <div className='exercise-btn'>
            <motion.button
              animate={controls}
              onClick={NextStep} className="btn hidden-button">
              <FontAwesomeIcon
                  icon={solid("play")}
                  style={{ marginRight: "10px" }}
                />
                Next Step
            </motion.button>
            <button className="btn-retry" onClick={Retry}>
              <FontAwesomeIcon
                icon={solid("rotate-left")}
                style={{ marginRight: "10px" }}
              />
              Recommencer
            </button>
            <button className="btn-return" onClick={PreviousStep}>
              <FontAwesomeIcon
                icon={solid("backward-step")}
                style={{ marginRight: "10px" }}
              />
              Retour
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
