import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Logo from "./Logo";
import { useAuth } from "../../Auth/src/pages/Auth";

export default function Navbar() {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  function handleClick() {
    navigate('/signup', { replace: true });
  }

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleLogout = () => {
    // Call your logout API to log out the user
    const csrfToken = document.cookie
      .split(";")
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith("csrf_access_token="))
      ?.split("=")[1];
    const headers = {
      'X-CSRF-TOKEN': `${csrfToken}`
    }
    fetch("https://app.calinfra.fr/logout", {
      method: "DELETE",
      headers: headers,
    })
      .then((response) => {
        if (response.ok) {
          setIsLoggedIn(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <nav className="navbar">
      <div className="left">
        <Logo />
      </div>
    </nav>
  );
}
