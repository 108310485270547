import Navbar from "../../../Common/components/Navbar";
import { useEffect, useState, useRef, FC, useCallback } from "react";
import "xterm/css/xterm.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import ExerciseHeader from "../../../Common/components/ExerciseHeader";
import Button from "../../../Common/components/Button";
import ButtonNext from "../../../Common/components/ButtonNext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, useAnimationControls } from "framer-motion";
import validation from "../../../Common/assets/validation.png";
import Markdown from "react-markdown";
import "./SingleExercise.scss";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import Cookies from 'universal-cookie';

interface ProgressBarProps {
  numExercises: number; // Spécifiez le type de numExercises ici
  exerciseId: number;
}
interface IframeProps {
  exerciseId: number;
  cookie: string;
  initialisation: boolean;
}

function ProgressBar({numExercises, exerciseId}: ProgressBarProps) {
  const [scrollPercentage, setScrollPercentage] = useState(((Number(exerciseId)-1) * 100) / 7);
  useEffect(() => {
    setScrollPercentage((Number(exerciseId) * 100) / numExercises);
  }, [exerciseId, numExercises]);

  return (
    <div className='progress-bar'>
      <div style={{ width: '50%' }}></div>
      <div style={{ display: 'flex', width: '50%', alignItems: 'center'}}>
        <div style={{ float: 'right', marginRight: '20px' }}>Progression</div>
        <div style={{ height: '10px', width: '50%', backgroundColor: '#ccc', float: 'right' }}>
        <div style={{ height: '100%', backgroundColor: '#007bff', width: `${scrollPercentage}%` }} />
      </div>
    </div>
    </div>
  );
}

function IframeComponent({exerciseId, cookie, initialisation}: IframeProps) {
  const iframeRef = useRef(null);
  const src = `https://app.calinfra.fr/backend/terminalDockerTest/${exerciseId}/`;
  const [isCookieReady, setIsCookieReady] = useState(false);

  useEffect(() => {
    if (cookie !== "" && initialisation) {
      // Le cookie est prêt, vous pouvez maintenant charger l'iframe
      setIsCookieReady(true);
    }
  }, [cookie, initialisation]);
  if (!isCookieReady) {
    // Attendez que le cookie soit prêt
    return <div>Attente du cookie...</div>;
  }
  return (
    <iframe
      frameBorder="0"
      id="iframe"
      ref={iframeRef}
      src={src}
      width='100%'
      height='100%'
    />
  );
}

export default function TestExercise() {
  const [showIframe, setShowIframe] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [rotate, setRotate] = useState(false);
  const controls = useAnimationControls()
  const {technoName} = useParams<{ technoName: string}>();
  const [numExercises, setNumExercises] = useState(0);
  const navigate = useNavigate();
  const [exerciseId, setExerciseId] = useState(0);
  const [exerciseText, setExerciseText] = useState({content: ""});
  const [exerciseType, setExerciseType] = useState({type: 0});
  const [cookie, setCookie] = useState("");
  const [init, setInit] = useState(false);
  
  const handlePopstate = () => {
    // L'utilisateur est retourné en arrière, décrémentez le compteur ici
    setExerciseId(exerciseId - 1);
  };

  //On utilise cette fonction pour décrémenter lorsque le user fait un previous button
  useEffect(() => {
    // Ajoute un gestionnaire d'événement pour l'événement "popstate"
    window.addEventListener("popstate", handlePopstate);

    // Retire le gestionnaire d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  //On récupère au début le cookie de session
  useEffect(() => {
    const cookie = new Cookies(null, {path: '/'});
    document.cookie = "session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    setShowIframe(true);
    controls.start({ opacity: 0 });
    
    fetch(`https://app.calinfra.fr/backend/getCookie`)
    .then(response => {
      if (response.status === 401 || response.status === 422){
        // Navigate to another page if the response status is 401 Unauthorized
        navigate("/signin");
        return ""
      }
      else {
        return response.text();
      }
    })
    .then(text => JSON.parse(text))
    .then(data => {cookie.set('session_id', data.session_id); setCookie(data.session_id);})
    .catch(error => console.log(error));

  }, []);

  //On init les pods pour les exercices
  useEffect(() => {
    if (cookie !== "") {
      fetch(`https://app.calinfra.fr/backend/exercisesTest/Docker/pods/${exerciseId}`)
        .then(response => {
          if (response.status === 401 || response.status === 422) {
            // Navigate to another page if the response status is 401 Unauthorized
            navigate("/signin");
            return "";
          } else {
            return response.text();
          }
        })
        .then(text => JSON.parse(text))
        .then(data => {setInit(true);})
        .catch(error => console.error(error));
    }
  }, [cookie]);

  //On récupère le type de l'exercise
  useEffect(() => {
    if (cookie !== "" && init === true) {
      fetch(`https://app.calinfra.fr/backend/exercisesTest/Docker/type/${exerciseId}`)
        .then(response => {
          if (response.status === 401 || response.status === 422) {
            // Navigate to another page if the response status is 401 Unauthorized
            navigate("/signin");
            return "";
          } else {
            return response.text();
          }
        })
        .then(text => JSON.parse(text))
        .then(data => {setExerciseType(data);})
        .catch(error => console.error(error));
    }
  }, [cookie, exerciseId, init]);

  //On récupère le texte pour l'exercise
  useEffect(() => {
    if (cookie !== "" && init === true) {
    fetch(`https://app.calinfra.fr/backend/exercisesTest/Docker/${exerciseId}`)
        .then(response => {
          if (response.status === 401 || response.status === 422) {
            // Navigate to another page if the response status is 401 Unauthorized
            navigate("/signin");
            return "";
          } else {
            return response.text();
          }
        })
        .then(text => JSON.parse(text))
        .then(data => {setExerciseText(data);})
        .catch(error => console.error(error));
    }
  }, [cookie, exerciseId, init]);

  useEffect(() => {
    // Fetch number of exercises for the lesson
    fetch(`https://app.calinfra.fr/backend/exercisesTest/number/${technoName}`)
      .then(response => response.json())
      .then(data => setNumExercises(data.number))
      .catch(error => console.error(error));
  }, [technoName]);

  const NextStep = async() => {
    const incrementedParam = exerciseId + 1; // add 1 to the integer
    if (incrementedParam < numExercises) {
      // Si l'exercice suivant est inférieur ou égal au nombre total d'exercices, passez à l'exercice suivant
      try {
        const response = await fetch(`https://app.calinfra.fr/backend/verificationTest/${technoName}/${exerciseId}`);
        if (response.status === 200) {
          // La réponse est 200 OK
          const data = await response.json(); // Si la réponse est au format JSON
          // Faites quelque chose avec les données reçues (dans 'data')
        } else {
          // La réponse n'est pas 200 OK, gérez en conséquence
          console.error("La réponse n'est pas 200 OK");
        }
      } catch (error) {
        // Gérez les erreurs de requête
        console.error("Erreur lors de la requête : " + error);
      }
      setExerciseId(exerciseId+1);
    }
    else if (incrementedParam === numExercises){
      // Si c'est le dernier exercice on n'incrémente pas la variable
      try {
        const response = await fetch(`https://app.calinfra.fr/backend/verificationTest/${technoName}/${exerciseId}`);
        if (response.status === 200) {
          // La réponse est 200 OK
          const data = await response.json(); // Si la réponse est au format JSON
          // Faites quelque chose avec les données reçues (dans 'data')
        } else {
          // La réponse n'est pas 200 OK, gérez en conséquence
          console.error("La réponse n'est pas 200 OK");
        }
        navigate(`/resultfreetry/${technoName}`);
      } catch (error) {
        // Gérez les erreurs de requête
        console.error("Erreur lors de la requête : " + error);
      }
    } 
  };

  const PreviousStep = async() => {
    const iframe = document.querySelector("iframe");
    await navigate(-1);
  }

  const Retry = async() => {
    const iframe = document.querySelector("iframe");
  }

  useEffect(() => {
    const waitForIframeLoad = () => {
      const iframe = document.querySelector("iframe");
      setTimeout(waitForIframeLoad, 1000);
    };
  
    waitForIframeLoad();
  }, [technoName,exerciseId,showIframe]);

  const [exerciseList, setExerciseList] = useState([]);

  useEffect(() => {
    // Fetch exercise list from backend
    fetch(`https://app.calinfra.fr/backend/exercisesTest/${technoName}/list`)
      .then(response => response.json())
      .then(data => {
        const sortedExercises = data.sort((a: any, b: any) => a.idExercise - b.idExercise);
        setExerciseList(sortedExercises);
      })
      .catch(error => console.error(error));
  }, [technoName]);

  return (
    <div className='code'>
      <ProgressBar numExercises={numExercises} exerciseId={exerciseId}/>
      <ExerciseHeader />
      <div className='content'>
        <div className='exercise'>
          <div className='row exercise-content'>
            <div className="sujet-div">
              <Markdown skipHtml={false} className='sujet'>{exerciseText.content}</Markdown>
              {exerciseType.type === 2 && (
                <div className="input-div">
                  <p>Entrez l'ip puis cliquez sur Valider : </p>
                  <input type="text" placeholder="ex: 172.0.0.1" />
                </div>
              )}
            </div>
            <div className="vscode center">
            {showIframe && <IframeComponent exerciseId={exerciseId} cookie={cookie} initialisation={init}/>}
            {!showIframe && <img src={validation} alt="success!" style={{ width: "10%", height: "auto" }} />}
            </div>
          </div>
          <div className='exercise-btn'>
            <button className="btn" onClick={NextStep}>
              <FontAwesomeIcon
                icon={solid("play")}
                style={{ marginRight: "10px" }}
              />
              Valider
            </button>
            <button className="btn-retry" onClick={Retry}>
              <FontAwesomeIcon
                icon={solid("rotate-left")}
                style={{ marginRight: "10px" }}
              />
              Recommencer
            </button>
            <button className="btn-return" onClick={PreviousStep}>
              <FontAwesomeIcon
                icon={solid("backward-step")}
                style={{ marginRight: "10px" }}
              />
              Retour
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
