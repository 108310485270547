import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input, Flex, Segmented } from 'antd';
import "./CGU.scss"

export default function CGU() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add('dashboard-body');
  }, []);

  return (
    <Flex justify="center" align="center">
        <div className="content-cgu">
        <h1 className="h1-cgu">Conditions Générales d'Utilisation (CGU)</h1>
        
        <section>
            <h2>1. Introduction</h2>
            <p className="p-cgu">
            Bienvenue sur Calinfra ! Les présentes conditions générales d'utilisation (ci-après les "CGU") régissent votre utilisation de notre plateforme en ligne dédiée à la création et à l'envoi de tests techniques orientés infrastructure pour les Devops, SRE et Cloud engineers. En utilisant notre service, vous acceptez sans réserve les présentes CGU.
            </p>
        </section>

        <section>
            <h2>2. Description du Service</h2>
            <p className="p-cgu">
            Calinfra permet aux utilisateurs de créer des tests techniques orientés infrastructure et de les envoyer à des candidats. Les tests sont hébergés et exécutés sur nos serveurs. Pour utiliser ce service, les utilisateurs doivent créer un compte et souscrire à une offre payante.
            </p>
        </section>

        <section>
            <h2>3. Création de Compte</h2>
            <p className="p-cgu">
            Pour accéder à notre service, vous devez créer un compte en fournissant des informations exactes et complètes. Vous êtes responsable de la confidentialité de votre identifiant et mot de passe et de toutes les activités effectuées sous votre compte.
            </p>
        </section>

        <section>
            <h2>4. Offres et Tarification</h2>
            <p className="p-cgu">
            L'utilisation de Calinfra est soumise à un abonnement payant. Les détails des offres et des tarifs sont disponibles sur notre site. Nous nous réservons le droit de modifier nos tarifs et nos offres à tout moment, sous réserve de vous en informer au préalable.
            </p>
        </section>

        <section>
            <h2>5. Paiement</h2>
            <p className="p-cgu">
            Les paiements pour les abonnements sont effectués via Stripe. En souscrivant à un abonnement, vous acceptez de payer les frais indiqués pour l'offre choisie.
            </p>
        </section>

        <section>
            <h2>6. Utilisation du Service</h2>
            <p className="p-cgu">
            Vous vous engagez à utiliser notre service conformément aux présentes CGU et aux lois en vigueur. Vous vous interdisez notamment :
            <ul className="ul-cgu">
                <li>D'utiliser le service à des fins illégales ou non autorisées.</li>
                <li>De tenter d'interférer avec le fonctionnement du service ou de l'altérer.</li>
                <li>De violer les droits de propriété intellectuelle de Calinfra ou de tiers.</li>
            </ul>
            </p>
        </section>

        <section>
            <h2>7. Responsabilité</h2>
            <p className="p-cgu">
            Calinfra met en œuvre tous les moyens raisonnables pour assurer un service de qualité. Toutefois, nous ne saurions être tenus responsables des interruptions, pannes ou erreurs pouvant survenir. En aucun cas, Calinfra ne pourra être tenu responsable des dommages indirects résultant de l'utilisation ou de l'impossibilité d'utiliser le service.
            </p>
        </section>

        <section>
            <h2>8. Propriété Intellectuelle</h2>
            <p className="p-cgu">
            Tout le contenu disponible sur Calinfra, y compris les textes, graphiques, logos, images, et logiciels, est la propriété de Calinfra ou de ses concédants et est protégé par les lois en vigueur sur la propriété intellectuelle.
            </p>
        </section>

        <section>
            <h2>9. Confidentialité et Protection des Données</h2>
                <p className="p-cgu"><strong>Collecte des Données Personnelles</strong></p>
                <p className="p-cgu">Calinfra collecte et traite certaines données personnelles nécessaires à la fourniture de nos services. Cela peut inclure des informations telles que votre nom, votre adresse e-mail, des informations de paiement, et toute autre information nécessaire à l'exécution de nos services. Nous collectons ces données uniquement avec votre consentement ou lorsque cela est nécessaire pour remplir nos obligations contractuelles envers vous.</p>

                <p className="p-cgu"><strong>Utilisation des Données Personnelles</strong></p>
                <p className="p-cgu">Les données personnelles collectées sont utilisées dans les buts suivants :</p>
                <ul className="ul-cgu">
                <li>Pour créer et gérer votre compte utilisateur.</li>
                <li>Pour traiter vos transactions et paiements.</li>
                <li>Pour vous fournir un accès et utiliser nos services, y compris l'envoi de tests techniques.</li>
                <li>Pour vous contacter concernant des informations importantes sur notre service, comme des mises à jour, des modifications des CGU, ou des problèmes de sécurité.</li>
                <li>Pour améliorer et optimiser nos services, y compris l'analyse de l'utilisation de notre plateforme.</li>
                </ul>

                <p className="p-cgu"><strong>Conservation des Données</strong></p>
                <p className="p-cgu">Calinfra conserve vos données personnelles aussi longtemps que nécessaire pour atteindre les fins pour lesquelles elles ont été collectées, ainsi que pour se conformer à nos obligations légales et réglementaires. Si vous résiliez votre compte, nous pouvons conserver certaines informations pour respecter nos obligations légales, résoudre des litiges, ou appliquer nos accords.</p>

                <p className="p-cgu"><strong>Sécurité des Données</strong></p>
                <p className="p-cgu">Nous prenons la sécurité de vos données personnelles très au sérieux. Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour protéger vos données contre la perte, l'accès non autorisé, la divulgation, l'altération ou la destruction accidentelle. Cela inclut la sécurisation de nos systèmes informatiques, le contrôle d'accès, et la formation de notre personnel sur la sécurité des données.</p>

                <p className="p-cgu"><strong>Partage des Données</strong></p>
                <p className="p-cgu">Calinfra peut partager vos données personnelles avec des tiers dans les cas suivants :</p>
                <ul className="ul-cgu">
                <li>Avec des prestataires de services tiers qui nous aident à fournir nos services, comme les processeurs de paiement et les fournisseurs d'hébergement.</li>
                <li>Lorsque cela est nécessaire pour se conformer à des obligations légales ou pour répondre à des demandes d'application de la loi.</li>
                </ul>
                <p className="p-cgu">Nous ne vendons pas vos données personnelles à des tiers et nous nous engageons à ne pas les utiliser à d'autres fins que celles décrites dans nos CGU.</p>

                <p className="p-cgu"><strong>Vos Droits</strong></p>
                <p className="p-cgu">Vous avez certains droits concernant vos données personnelles, notamment :</p>
                <ul className="ul-cgu">
                <li>Le droit d'accéder à vos données personnelles et d'en obtenir une copie.</li>
                <li>Le droit de rectifier toute inexactitude dans vos données personnelles.</li>
                <li>Le droit de demander l'effacement de vos données personnelles, sous réserve de certaines exceptions.</li>
                <li>Le droit de limiter le traitement de vos données personnelles dans certaines circonstances.</li>
                <li>Le droit de retirer votre consentement lorsque nous traitons vos données personnelles sur la base de votre consentement.</li>
                </ul>
                <p className="p-cgu">Pour exercer ces droits ou pour toute question concernant la confidentialité et la protection des données, veuillez nous contacter à "calinfra.enterprise@gmail.com".</p>

                <p className="p-cgu"><strong>Modifications de la Politique de Confidentialité</strong></p>
                <p className="p-cgu">Calinfra se réserve le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront publiées sur notre site web et vous serez informé de tout changement significatif par email ou par une notification sur notre plateforme. En continuant à utiliser nos services après la publication de ces modifications, vous acceptez la politique de confidentialité mise à jour.</p>
        </section>

        <section>
            <h2>10. Modification des CGU</h2>
            <p className="p-cgu">
            Calinfra se réserve le droit de modifier les présentes CGU à tout moment. En cas de modification, vous serez informé par email ou via une notification sur notre site. En continuant à utiliser notre service après ces modifications, vous acceptez les CGU modifiées.
            </p>
        </section>

        <section>
            <h2>11. Résiliation</h2>
            <p className="p-cgu">
            Vous pouvez résilier votre compte à tout moment en nous contactant à "calinfra.enterprise@gmail.com". En cas de violation des présentes CGU, Calinfra se réserve le droit de suspendre ou de résilier votre compte sans préavis.
            </p>
        </section>

        <section>
            <h2>12. Loi Applicable</h2>
            <p className="p-cgu">
            Les présentes CGU sont régies par la loi française. Tout litige relatif à l'exécution ou à l'interprétation des présentes CGU sera soumis à la compétence exclusive des tribunaux français.
            </p>
        </section>

        <section>
            <h2>13. Contact</h2>
            <p className="p-cgu">
            Pour toute question concernant ces CGU, veuillez nous contacter à "calinfra.enterprise@gmail.com".
            </p>
        </section>
        </div>
    </Flex>
  );
}