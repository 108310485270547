import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../Auth/src/pages/Auth";

export default function SideBar() {
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const handleLogout = () => {
    // Call your logout API to log out the user
    const csrfToken = document.cookie
      .split(";")
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith("csrf_access_token="))
      ?.split("=")[1];
    const headers = {
      'X-CSRF-TOKEN': `${csrfToken}`
    }
    fetch("https://app.calinfra.fr/api/v1/logout", {
      method: "DELETE",
      headers: headers,
    })
      .then((response) => {
        if (response.ok) {
          setIsLoggedIn(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className='sidebar'>
      {/* <h2>Python Master</h2> */}

      <div className='items'>
        <div className='item'>
          <Link to="/">
            <FontAwesomeIcon
              icon={solid("home")}
              style={{ marginRight: "10px" }}
            />
            <p>Accueil</p>
          </Link>
        </div>
        <div className='item'>
          <FontAwesomeIcon
            icon={solid("user")}
            style={{ marginRight: "10px" }}
          />
          <p>Profil</p>
        </div>
        <div className='item selected'>
          <FontAwesomeIcon
            icon={solid("book")}
            style={{ marginRight: "10px" }}
          />
          <p>Cours</p>
        </div>
        <div className='item'>
          <Link to="/" onClick={handleLogout}>
            <FontAwesomeIcon
              icon={solid("arrow-right-from-bracket")}
              style={{ marginRight: "10px" }}
            />

            <p>Deconnexion</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
