import { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Alert,
  Flex,
} from "antd";
import "./SignIn.scss";
import { BASE_URL } from "../../../config";

const boxStyle: React.CSSProperties = {
  width: '100%',
  padding: "50px",
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 0,
    },
  },
};

export default function SignUp() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    firstname: "",
    company: "",
    password: "",
    confirmPassword: "",
  });
  const [form] = Form.useForm();

  useEffect(() => {
    document.body.classList.add('dashboard-body');
  }, []);


  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const onFinish = (values: any) => {
    const requestBody = {
      email: values.email,
      password: values.password,
      name: values.name,
      firstname: values.firstname,
      company: values.company,
    };

    fetch(`${BASE_URL}/api/v1/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "error") {
          setErrorMessage(data.msg);
        } else {
          navigate("/accountverification");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage("Invalid email or password");
      });
  };

  return (
    <div className="flex items-center justify-center">
    <div className="flex flex-col items-start gap-4 m-6 md:w-5/12">
      <div className="flex flex-col items-center justify-center gap-8 p-6 rounded-lg w-full">
        <h3 className="text-white">Inscription</h3>
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          className="w-full max-w-lg"
          size="large"
          scrollToFirstError
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Entrez votre nom !",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Nom" />
          </Form.Item>
          <Form.Item
            name="firstname"
            rules={[
              {
                required: true,
                message: "Entrez votre prénom !",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Prénom" />
          </Form.Item>
          <Form.Item
            name="company"
            rules={[
              {
                required: true,
                message: "Entrez le nom de votre entreprise !",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Entreprise" />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "L'email n'est pas valide",
              },
              {
                required: true,
                message: "Entrez votre email",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Alert
            message="Le mot de passe doit contenir:"
            description={
              <>
                <div>- au moins 12 caractères.</div>
                <div>- au moins un caractère spécial !@#$%^&*()-+[].</div>
                <div>- au moins un chiffre.</div>
                <div>- au moins une majuscule.</div>
              </>
            }
            type="info"
            className="mb-4"
          />
          <Form.Item
            name="password"
            style={{ marginTop: "10px" }}
            rules={[
              {
                required: true,
                message: "Entrez votre mot de passe",
              },
              {
                validator: (_, value) => {
                  const isValid =
                    value &&
                    value.length >= 12 &&
                    /[!@#$%^&*()-+\[\].]/.test(value) &&
                    /\d/.test(value) &&
                    /[A-Z]/.test(value);
                  if (!isValid) {
                    return Promise.reject(
                      "Le mot de passe ne respecte pas les critères requis"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Mot de passe"
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Confirmez votre mot de passe",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Les deux mot de passe ne sont pas identiques")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Confirmation du mot de passe"
            />
          </Form.Item>

          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          "Acceptez les conditions d'utilisation"
                        )
                      ),
              },
            ]}
          >
            <Checkbox className="text-gray-500">
              J'ai lu et j'accepte les{" "}
              <a href="/cgu" className="text-green-500">
                conditions d'utilisation
              </a>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              className="con-button w-full mb-2"
              htmlType="submit"
            >
              S'inscrire
            </Button>
            <Link to="/signin" className="text-green-500">
              Se connecter
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
    </div>
  );
}
