import { useNavigate } from "react-router-dom";
import Button from "../../../Common/components/Button";
import disconimage from "../../../Common/assets/disconnected-server.png";
import "./OupsyError.scss";
import { disconnect } from "process";

export default function OupsyError() {
  const navigate = useNavigate();
  const handleButtonLogin = () => {
    navigate("/signin");
  };
  const handleButtonPricing = () => {
    navigate("/pricing");
  };

  return (
    <div className="error-block">
        <div className="image-container">
        <img src={disconimage} alt="Description of your image" />
      </div>
      <div className="message">
        <h3>Oops ! Une erreur s'est produite</h3>
        <p>Nous travaillons actuellement sur la résolution du problème.</p>
      </div>
    </div>
  );
}
