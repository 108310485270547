// import anime from "animejs";
import React, { useEffect, useRef } from "react";
import imageIcon from "../assets/CalinfraIcon2.png";
//import Anime from "react-anime";
import anime from "animejs";

export default function Logo() {
  useEffect(() => {
    const timeline = anime.timeline({
      easing: "linear",
    });
    timeline.add({
      targets: "#Bookish-stroke path",
      strokeDashoffset: [700, 0],
      delay: 2000,
      duration: 2000,
      direction: "alternate",
      loop: false,
    });

    timeline.add({
      targets: "#Bookish-bg",
      opacity: 1,
      duration: 600,
      loop: false,
    });
  }, []);

  return (
    <div style={{ display: "flex", alignItems: "center"}}>
      <a href="/" >
      <img src={imageIcon} alt="" width={50}/>
      </a>
      <a href="/" className="logo-link w-nav-brand" style={{ marginLeft: "30px", fontSize: "27px" }}>
        Calinfra
      </a>
    </div>
  );
}
