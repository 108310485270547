import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import "./AccountEnable.scss"
import { BASE_URL } from "../../../config";

export default function EndTest() {

  return (
    <div className="flex flex-col items-center justify-start min-h-screen pt-[5%] font-montserrat text-lg">
      <div className="text-center p-8 w-[80%] min-w-[300px] max-w-[600px] mx-auto">
        <h3 className="text-3xl font-bold text-gray-100 mb-8">Félicitations ! Test Terminé</h3>
          <p className="text-xl text-gray-300 mb-6">
          Votre résultat a été envoyé à votre examinateur, qui vous recontactera dans les plus brefs délais.
          </p>
      </div>
        <button 
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mb-8"
          onClick={() => window.location.href = '/'}
        >
          Accueil
        </button>
    </div>
  );
}